import React, { useState } from "react";

function DailyReporting() {
  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">
            Daily Reporting
          </h2>
        </div>

        <div className="overflow-auto scrollbar">
          <div className="grid grid-cols-12 items-center gap-6 mb-20">
            <div className="sm:col-span-12 xxl:col-span-6 flex items-center gap-6">
              <div className="flex-1 w-full">
                <span className="text-black text-md mb-2 block text-left">
                  Report type​
                </span>
                <div className="relative w-full">
                  <select
                    name=""
                    className="block cursor-pointer appearance-none leading-1 py-2.5 pl-4 pr-11 border border w-full rounded-full outline-0 text-black"
                  >
                    <option value="Sales Summary">Sales Summary</option>
                    <option value="User">User</option>
                    <option value="Outlet">Outlet</option>
                    <option value="Register">Register</option>
                    <option value="Customer">Customer</option>
                  </select>
                  <svg
                    className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                      fill="#BABABA"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex-1 w-full">
                <span className="text-black text-md mb-2 block text-left">
                  Measure​
                </span>
                <div className="relative w-full">
                  <select
                    name=""
                    className="block cursor-pointer appearance-none leading-1 py-2.5 pl-4 pr-11 border border w-full rounded-full outline-0 text-black"
                  >
                    <option value="Revenue">Revenue</option>
                    <option value="Basket Size">Basket Size</option>
                    <option value="Basket Value">Basket Value</option>
                    <option value="Basket Value(tax incl.)">
                      Basket Value(tax incl.)
                    </option>
                    <option value="Cost of Goods">Cost of Goods</option>
                    <option value="Customer count">Customer count</option>
                  </select>
                  <svg
                    className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                      fill="#BABABA"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="sm:col-span-12 xxl:col-span-6 flex items-center gap-6">
              <div className="flex-1 w-full">
                <span className="text-black text-md mb-2 block text-left">
                  Report period
                </span>
                <div className="border border w-full rounded-full outline-0 text-black flex items-center flex-wrap gap-2 py-2.5 px-4">
                  <span className="cursor-pointer uppercase text-sm xxl:text-md flex-1 flex items-center justify-center font-semibold text-redOrange active">
                    1w
                  </span>
                  <span className="cursor-pointer uppercase text-sm xxl:text-md flex-1 flex items-center justify-center font-semibold text-ufoGreen">
                    4w
                  </span>
                  <span className="cursor-pointer uppercase text-sm xxl:text-md flex-1 flex items-center justify-center font-semibold text-ufoGreen">
                    1y
                  </span>
                  <span className="cursor-pointer uppercase text-sm xxl:text-md flex-1 flex items-center justify-center font-semibold text-ufoGreen">
                    Mtd
                  </span>
                  <span className="cursor-pointer uppercase text-sm xxl:text-md flex-1 flex items-center justify-center font-semibold text-ufoGreen">
                    Qtd
                  </span>
                  <span className="cursor-pointer uppercase text-sm xxl:text-md flex-1 flex items-center justify-center font-semibold text-ufoGreen">
                    Ytd
                  </span>
                  <span className="cursor-pointer uppercase text-sm xxl:text-md flex-1 flex items-center justify-center font-semibold text-ufoGreen">
                    Custom
                  </span>
                </div>
              </div>
              <div className="flex-1 w-full">
                <span className="text-black text-md mb-2 block text-left">
                  Comparison type
                </span>
                <div className="relative w-full">
                  <select
                    name=""
                    className="block cursor-pointer appearance-none leading-1 py-2.5 pl-4 pr-11 border border w-full rounded-full outline-0 text-black"
                  >
                    <option value="No Comparison">No Comparison</option>
                    <option value="Same dates in previous year">
                      Same dates in previous year
                    </option>
                    <option value="Same period in previous year">
                      Same period in previous year
                    </option>
                  </select>
                  <svg
                    className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
                      fill="#BABABA"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="sm:text-xl xxl:text-2xl font-medium mb-6">
              Revenue (Sales summary) 29th Dec 2024 - 4th Jan 2025
            </div>
          </div>

          <table class="table-auto w-full no-wrap overflow-y-scroll border border-lightBlue">
            <thead>
              <tr>
                <th class="text-lg py-4 border-b border-lightBlue"></th>
                <th
                  class="text-lg text-center py-4 font-normal text-redOrange bg-veryLightGrey border-b border-lightBlue"
                  colSpan={6}
                >
                  TOTALS BY SALES SUMMARY
                </th>
              </tr>
              <tr>
                <th className="text-left font-light text-sm py-2 border-b border-lightBlue px-4">
                  Sales Summary
                </th>
                <th className="text-left font-light text-sm py-2 border-b border-lightBlue border-r">
                  29th Dec
                </th>
                <th className="text-left font-light text-sm py-2 border-b border-lightBlue">
                  Revenue
                </th>
                <th className="text-left font-light text-sm py-2 border-b border-lightBlue">
                  Cost of Goods
                </th>
                <th className="text-left font-light text-sm py-2 border-b border-lightBlue">
                  Gross Profit
                </th>
                <th className="text-left font-light text-sm py-2 border-b border-lightBlue">
                  Margin
                </th>
                <th className="text-left font-light text-sm py-2 border-b border-lightBlue">
                  Tax
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left font-bold text-sm py-2 flex items-center gap-10 px-4">
                  Total
                  <svg
                    className="cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fe5e5e"
                    viewBox="0 0 256 256"
                  >
                    <path d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z"></path>
                  </svg>
                </td>
                <td className="text-left font-bold text-sm py-2 border-b border-lightBlue border-r">
                  Rs0.00
                </td>
                <td className="text-left font-bold text-sm py-2 border-b border-lightBlue">
                  Rs0.00
                </td>
                <td className="text-left font-bold text-sm py-2 border-b border-lightBlue">
                  Rs0.00
                </td>
                <td className="text-left font-bold text-sm py-2 border-b border-lightBlue">
                  Rs0.00
                </td>
                <td className="text-left font-bold text-sm py-2 border-b border-lightBlue">
                  Rs0.00
                </td>
                <td className="text-left font-bold text-sm py-2 border-b border-lightBlue">
                  Rs0.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default DailyReporting;
