export function filterSidebarLinks(sidebarLinks, userPermissions) {
  const userPermissionKeys = userPermissions?.permissions?.map(
    (perm) => perm.key,
  );

  return sidebarLinks
    .map((link) => {
      if (link.children && link.children.length > 0) {
        const filteredChildren = link.children.filter((child) =>
          userPermissionKeys.includes(child.permission),
        );
        if (filteredChildren.length === 0) {
          return null;
        }

        return {
          ...link,
          children: filteredChildren,
        };
      }

      return link;
    })
    .filter(Boolean);
}
