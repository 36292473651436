import React from "react";

function BasicCard({
  heading,
  children,
  buttonTxt,
  onClickBtn,
  className = "",
}) {
  return (
    <div
      className={`bg-white sm:rounded-xl xxl:rounded-3xl relative sm:py-4 xxl:py-5 sm:px-4 xxl:px-6 mb-4 ${className}`}
    >
      <div className="flex justify-between items-center border-b pb-2.5">
        <h3 className="text-ufoGreen sm:text-md xxl:text-xl font-medium">
          {heading}
        </h3>
        {buttonTxt?.length && (
          <a
            onClick={onClickBtn}
            className="bg-redOrange text-white px-4 py-2 rounded-full"
          >
            {buttonTxt}
          </a>
        )}
      </div>
      {children}
    </div>
  );
}

export default BasicCard;
