import React from "react";
import { safeNumber } from "../../../utils/helper";

function TaxSummary({ registerData }) {
  const { transaction } = registerData || {};
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left w-64">
            Tax
          </th>
          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
            Sales
          </th>
          <th className="font-medium text-sm py-2 border-b border-lightBlue text-right">
            Tax
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-left font-light text-sm py-2">GST</td>
          <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
            {safeNumber(transaction?.total)}
          </td>
          <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
            {safeNumber(transaction?.gst)}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td className="" />
          <td className="text-left font-medium text-sm py-2">Total</td>
          <td className="text-right font-medium text-sm text-ufoGreen py-2">
            {(transaction?.total || 0) * (1 + (transaction?.gst || 0) / 100)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default TaxSummary;
