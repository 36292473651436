import { useSelector } from "react-redux";
import { getSelectedOutlet } from "../../../redux/feature/outletSlice";
import { getUserDetails } from "../../../redux/feature/authSlice";

const CashRegisterInvoice = ({
  invoiceRef,
  registerData,
  cashDifference,
  cardDifference,
  creditDifference,
}) => {
  const { transaction, createdBy } = registerData || {};
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const { user } = useSelector(getUserDetails) || {};
  const safeNumber = (value) =>
    isNaN(Number(value)) ? 0 : Number(value).toFixed(2);

  const formatNumber = (value) => {
    const number = Number(value);
    return isNaN(number) ? "0" : number.toFixed(2);
  };
  const calculate = (rec, ref) => {
    return Number(Number(rec) - Number(ref)).toFixed(2);
  };

  return (
    <>
      <div id="invoice" ref={invoiceRef}>
        <>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>{registerData?.cashRegister?.name}</title>
          <table
            style={{
              width: 700,
              maxWidth: "100%",
              margin: "15px auto 0",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "left", fontSize: 22 }}>
                  {registerData?.cashRegister?.name}
                </th>
                <th style={{ textAlign: "right", fontSize: 22 }}>
                  Register summary
                </th>
              </tr>
              <tr>
                <td
                  style={{ textAlign: "left", fontSize: 13, padding: "8px 0" }}
                >
                  {`${selectedOutlet?.state}, ${selectedOutlet?.city}, ${selectedOutlet?.street}`}
                </td>
                <td
                  style={{ textAlign: "right", fontSize: 13, padding: "8px 0" }}
                >
                  {registerData?.startTime
                    ? `${new Date(registerData.startTime).toLocaleDateString()} - ${new Date(registerData.startTime).toLocaleTimeString()}`
                    : "No Date Available"}{" "}
                  to{" "}
                  {`${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`}
                </td>
              </tr>
            </thead>
          </table>
          <table
            style={{
              width: 700,
              maxWidth: "100%",
              margin: "15px auto 0",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Payment
                </th>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Expected
                </th>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Counted
                </th>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Difference
                </th>
              </tr>
            </thead>
            <tbody style={{ borderBottom: "2px solid #000" }}>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  Cash
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {safeNumber(transaction?.cash)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {registerData?.transaction?.cash}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {cashDifference || 0}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  Card
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {transaction?.card || 0}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {registerData?.transaction?.card}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {cardDifference || 0}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Store credit
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "2px solid #000",
                  }}
                >
                  {transaction?.credit || 0}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "2px solid #000",
                  }}
                >
                  {registerData?.transaction?.credit}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "2px solid #000",
                  }}
                >
                  {creditDifference || 0}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                  }}
                  colSpan={2}
                />
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  Payments:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {safeNumber(transaction?.received)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                  }}
                  colSpan={2}
                />
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  Refunds:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {safeNumber(transaction?.refund)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                  }}
                  colSpan={2}
                />
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  Net receipts:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {calculate(
                    transaction?.received || 0,
                    transaction?.refund || 0,
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{
              width: 700,
              maxWidth: "100%",
              margin: "15px auto 0",
              borderCollapse: "collapse",
            }}
          >
            <tbody style={{ borderBottom: "2px solid #000" }}>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  Total sales:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  {formatNumber(registerData?.transaction?.total)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  Sales tax collected:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  {safeNumber(transaction?.gst)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  GST &nbsp;{" "}
                  <small>(Sales: {safeNumber(transaction?.total)})</small>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  {safeNumber(transaction?.gst)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  Item discounts:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  {formatNumber(registerData?.transaction?.itemDiscount)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  Order discounts:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  {formatNumber(registerData?.transaction?.orderDiscount)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  Surcharge:
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                    width: "50%",
                  }}
                >
                  {formatNumber(registerData?.transaction?.surcharge)}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{
              width: 700,
              maxWidth: "100%",
              margin: "15px auto 0",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Transaction
                </th>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  User
                </th>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Notes
                </th>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #000",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody style={{ borderBottom: "2px solid #000" }}>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  Opening float
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {createdBy?.fullName || "N/A"}
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {registerData?.notes}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {safeNumber(transaction?.openingBalance)}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{
              width: 700,
              maxWidth: "100%",
              margin: "15px auto 0",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #e1e1e1",
                  }}
                >
                  New customers
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #e1e1e1",
                  }}
                >
                  Number of transactions
                </th>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 0",
                    borderBottom: "2px solid #e1e1e1",
                  }}
                >
                  Avg. sale value
                </th>
              </tr>
            </thead>
            <tbody style={{ borderBottom: "2px solid #000" }}>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  0
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {transaction?.totalTransactions}
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontSize: 13,
                    padding: "8px 4px",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  {formatNumber(registerData?.transaction?.avgSalesVolume)}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{
              width: 700,
              maxWidth: "100%",
              margin: "8px auto 15px",
              borderCollapse: "collapse",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{ textAlign: "left", fontSize: 13, padding: "8px 0" }}
                >
                  Opened by: {createdBy?.fullName || "N/A"}
                </td>
              </tr>
              <tr>
                <td
                  style={{ textAlign: "left", fontSize: 13, padding: "8px 0" }}
                >
                  Closed by: {user?.fullName}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </div>
    </>
  );
};
export default CashRegisterInvoice;
