import React, { useEffect, useState } from "react";
import CustomModal from "../../../component/shared/modal";
import SearchInput from "../../../component/shared/searchInput";
import CustomTable from "../../../component/shared/customTable";
import {
  changeRoleStatus,
  createRole,
  deleteRoleApi,
  getAllRolePermissionApi,
  updateRole,
  updateRolePermissionApi,
} from "../../../services/user/role";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { Form } from "antd";
import AddRole from "../../../component/user/role/addRole";
import AddPermisions from "../../../component/user/role/permission";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";

function Role() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const [id, setId] = useState(null);
  const [roleId, setRoleId] = useState(null);

  const [refreshTable, setRefreshTable] = useState(false);
  const [form] = Form.useForm();
  const [permissionForm] = Form.useForm();

  const getUserPermissions = async () => {
    try {
      const { data } = await getAllRolePermissionApi(roleId);
      if (data.meta.success) {
        setPermissions(data.data);
      }
    } catch (err) {
      ServerError();
    }
  };

  useEffect(() => {
    if (roleId) {
      getUserPermissions();
    }
  }, [roleId]);

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeRoleStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteRoleApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateRole(payload, id);
      } else {
        response = await createRole(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Role successfully saved");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onFinishPermission = async (values) => {
    const result = {
      permissionIds: values.permissions || [], // Use empty array if no permissions selected
    };
    try {
      const { data } = await updateRolePermissionApi(result, roleId);
      if (data.meta.success) {
        setPermissions([]);
        setRoleId(null);
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
        permissionForm.resetFields();
        setIsPermissionModalOpen(false);
      } else {
        ServerError();
      }
    } catch {
      ServerError();
    }
  };
  const onClickEdit = async (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  const onClickRoleAndPermission = async (row) => {
    setRoleId(row._id);
    setIsPermissionModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const closePermissionModal = () => {
    form.resetFields();
    setRoleId(null);
    setIsPermissionModalOpen(false);
  };
  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
    },
  ];
  const onClickAddRole = () => {
    openModal();
    setId(null);
    setRoleId(null);
  };
  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8 text-left">
          <h2 className="sm:text-xl xxl:text-2xl font-medium flex-1">Role</h2>
          <ActionAccessWrapper permissionKeys={"CreateRoles"}>
            <a
              onClick={onClickAddRole}
              id="addProductTypeBtn"
              className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add Role
            </a>
          </ActionAccessWrapper>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Add Role"}
          >
            <AddRole form={form} onFinish={onFinish} />
          </CustomModal>
          <CustomModal
            isModalOpen={isPermissionModalOpen}
            closeModal={closePermissionModal}
            heading={"Edit Permission"}
            className="max-w-[50%]"
          >
            <AddPermisions
              form={permissionForm}
              onFinish={onFinishPermission}
              permissions={permissions}
            />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar roleDeatils-table">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"role/list"}
            search={searchText}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            handlePublish={onClickRoleAndPermission}
            showActions={true}
            permissions={{
              edit: "UpdateRoleById",
              status: "UpdateRoleById",
              delete: "DeleteRoleById",
              publish: "SaveRoleAllPermission",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default Role;
