import React from "react";
import { Select, Form, Input, Switch } from "antd";

function AddCustomer({ form, onFinish, tier, tag, gender }) {
  const { Option } = Select;
  return (
    <Form onFinish={onFinish} form={form}>
      <div className="grid grid-cols-12 h-[600px] w-[900px] text-left">
        <div className="col-span-8">
          <h3 className="text-ufoGreen text-xl font-medium  border-b pb-2">
            Primary Details
          </h3>
          <div class="flex items-center justify-start mt-6 gap-5 pr-5">
            {/* First Name */}
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: "Please enter first name" }]}
              className="flex-1"
            >
              <input
                type="text"
                placeholder="First Name"
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>

            {/* Last Name */}
            <Form.Item name="lastName" className="flex-1">
              <input
                type="text"
                placeholder="Last Name"
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
          </div>
          <div class="flex items-center justify-start gap-5 pr-5">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
              className="flex-1"
            >
              <input
                // type="email"
                placeholder="Email"
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
            {/* Phone */}
            <Form.Item name="phone" className="flex-1">
              <input
                type="text"
                placeholder="Phone"
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
          </div>

          <div className="pr-5">
            {/* Company Name */}
            <Form.Item name="notes">
              <Input.TextArea
                type="text"
                placeholder="Notes"
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-xl outline-0"
              />
            </Form.Item>
          </div>

          <h3 className="text-ufoGreen text-xl font-medium  border-b pb-2">
            Company Details
          </h3>
          <div className="pt-5 pr-5">
            {/* Company Name */}
            <Form.Item name="companyName">
              <input
                type="text"
                placeholder="Company Name"
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>

            <div className="flex gap-5">
              {/* Supplier Code */}
              {/* <Form.Item name="supplierCode" className="flex-1" rules={[{ required: true, message: 'Please enter supplier code' }]}>
                                <input
                                    type="text"
                                    placeholder="Supplier Code"
                                    className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
                                />
                            </Form.Item> */}
            </div>

            {/* Website */}
            {/* <Form.Item name="website" rules={[{ required: true, message: 'Please enter website' }]}>
                            <input
                                type="text"
                                placeholder="Website"
                                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
                            />
                        </Form.Item> */}

            <div className="grid grid-cols-12 gap-3">
              {/* <div className="col-span-6">
                                <div className="flex-1 flex items-center justify-between border-b py-3">
                                    <h3 className="text-ufoGreen text-xl font-medium">Mailing Address</h3>
                                </div>

                                <Form.Item name="address" rules={[{ required: true, message: 'Please enter street address' }]}>
                                    <input
                                        type="text"
                                        placeholder="Street Address"
                                        className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
                                    />
                                </Form.Item>

                                <div className="flex gap-5">
                                    <Form.Item name="city" className="flex-1" rules={[{ required: true, message: 'Please enter city' }]}>
                                        <input
                                            type="text"
                                            placeholder="City"
                                            className="text-center block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
                                        />
                                    </Form.Item>

                                    <Form.Item name="state" className="flex-1" rules={[{ required: true, message: 'Please enter state' }]}>
                                        <input
                                            type="text"
                                            placeholder="State"
                                            className="text-center block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
                                        />
                                    </Form.Item>
                                </div>

                                <div className="flex gap-5">
                                    <Form.Item name="country" className="flex-1" rules={[{ required: true, message: 'Please select country' }]}>
                                        <Select placeholder="Country" className="mt-6 block w-full rounded-full">
                                            <Option value="pakistan">Pakistan</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div> */}

              <div className="col-span-12">
                <div className="flex items-center justify-between border-b pb-3">
                  <h3 className="text-ufoGreen text-xl font-medium">
                    Physical Address
                  </h3>
                </div>

                {/* Physical Street Address */}
                <Form.Item name="address">
                  <input
                    type="text"
                    placeholder="Street Address"
                    className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
                  />
                </Form.Item>

                <div className="flex gap-5">
                  {/* City */}
                  <Form.Item name="city" className="flex-1">
                    <input
                      type="text"
                      placeholder="City"
                      className="text-center block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
                    />
                  </Form.Item>

                  {/* State */}
                  <Form.Item name="state" className="flex-1">
                    <input
                      type="text"
                      placeholder="State"
                      className="text-center block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
                    />
                  </Form.Item>
                </div>

                <div className="flex gap-5">
                  {/* Country */}
                  <Form.Item name="country" className="flex-1">
                    <input
                      type="text"
                      placeholder="Country"
                      className="text-center block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
                    />
                  </Form.Item>
                  {/* <Form.Item
                    name="country"
                    className="flex-1"
                    rules={[
                      { required: true, message: "Please select country" },
                    ]}
                  >
                    <Select
                      placeholder="Country"
                      className="block h-auto w-full rounded-full"
                    >
                      <Option value="PK">Pakistan</Option>
                    </Select>
                  </Form.Item> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-4">
          <h3 className="text-ufoGreen text-xl font-medium  pb-2 pl-5 rounded-tr-3xl">
            Additional Information
          </h3>
          <div className="p-5 pb-16 border border-t-0 rounded-b-3xl">
            {/* Mobile */}
            {/* <Form.Item
              name="mobile"
              rules={[
                { required: true, message: "Please enter mobile number" },
              ]}
              className="first:mt-0 mt-6"
            >
              <input
                type="text"
                placeholder="Mobile"
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item> */}
            <Form.Item name="accountLimit" className="flex-1">
              <input
                // type="number"
                placeholder="Credit Limit"
                className="text-center block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
            <Form.Item name="genderId" className="first:mt-0 mt-4">
              <Select
                placeholder="Gender"
                className="h-auto"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {gender.map((data, key) => {
                  return (
                    <Option key={key} value={data._id}>
                      {data.value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="customerGroupTag" className="first:mt-0 mt-4">
              <Select
                placeholder="Tag"
                className="h-auto"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {tag.map((data, key) => {
                  return (
                    <Option key={key} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="customerGroupTierId" className="first:mt-0 mt-4">
              <Select
                placeholder="Group Tier"
                className="h-auto"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {tier.map((data, key) => {
                  return (
                    <Option key={key} value={data._id}>
                      {data.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="dateOfBirth" className="first:mt-0 mt-4">
              <input
                type="date"
                placeholder=""
                className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
            <div className="text-black mt-3 font-medium">
              Signup for loyality rewards program
            </div>
            <Form.Item
              name="signUpForLoyality"
              rules={[{ required: false, message: "Please select an option" }]}
            >
              <Switch defaultChecked={false} />
            </Form.Item>
            <div className="text-black mt-3 font-medium">
              Customer has optedin to receive marketing & promo communications
            </div>
            <Form.Item
              name="optOutForMarketing"
              rules={[{ required: false, message: "Please select an option" }]}
            >
              <Switch defaultChecked={false} />
            </Form.Item>
            <div className="text-black mt-3 font-medium">
              This customer is tax exempted. No tax applied when this customer
              allocate to sale
            </div>
            <Form.Item
              name="taxExempted"
              rules={[{ required: false, message: "Please select an option" }]}
            >
              <Switch defaultChecked={false} />
            </Form.Item>
            {/* Default Tax */}

            {/* <span className="block mb-2 text-md text-lightGrey">
              Default Tax
            </span>
            <Form.Item name="companyTaxId" className="first:mt-0 mt-2">
              <Select placeholder="Select Default Tax" className="h-auto">
                <Option value="tax1">Tax 1</Option>
                <Option value="tax2">Tax 2</Option>
              </Select>
            </Form.Item> */}

            {/* Invoice Payment Due Date */}
            {/* <span className="block text-md text-lightGrey">
              Invoices payment due date (on account sales)
            </span> */}
            {/* <Form.Item name="invoicePaymentDueDate" className="first:mt-0 mt-4">
                            <Select
                                placeholder="Select Due Date"
                                className="block cursor-pointer appearance-none leading-1 py-2.5 px-4 border border-lightGrey w-full rounded-full outline-0"
                            >
                                <Option value="none">None</Option>
                                <Option value="1">1</Option>
                                <Option value="7">7</Option>
                                <Option value="14">14</Option>
                                <Option value="30">30</Option>
                            </Select>
                        </Form.Item> */}
          </div>
        </div>

        <div className="col-span-12 flex items-center justify-start gap-3 mt-2 mb-4">
          <button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full w-full text-white font-medium"
          >
            Save
          </button>
        </div>
        <br></br>
      </div>
    </Form>
  );
}

export default AddCustomer;
