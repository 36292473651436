import React, { useEffect, useState } from "react";
import CustomTable from "../../component/shared/customTable";
import CustomModal from "../../component/shared/modal";
import AddCustomer from "../../component/customer/addCustomer";
import { Form, Image, message } from "antd";
import {
  getAllListOfValueByKey,
  getSlugListOfValuesByKey,
} from "../../services/globalService";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import {
  createCustomer,
  deleteCustmer,
  updateCustomer,
} from "../../services/customer";
import ActionAccessWrapper from "../../component/shared/actionAccessWrapper";
import moment from "moment";

function Customer() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gender, setGender] = useState([]);
  const [tier, setTier] = useState([]);
  const [tag, setTag] = useState([]);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const [form] = Form.useForm();

  const fetchListOfValues = async () => {
    try {
      const { data: response } =
        await getSlugListOfValuesByKey("customer-tier");
      setTier(response.data.data);
      const { data: tagData } = await getSlugListOfValuesByKey("customer-tag");
      setTag(tagData.data.data);
    } catch {
      return ServerError();
    }
  };

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("gender");
      setGender(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  useEffect(() => {
    fetchListOfValues();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickHandle = (row) => {
    setId(row._id);
    form.setFieldsValue({
      ...row,
      dateOfBirth: moment(row.dateOfBirth).format("YYYY-MM-DD"),
    });
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (_, row) => {
        return `CUS-${row.code}`;
      },
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
  ];

  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteCustmer(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;

    try {
      const payload = {
        ...values,
        companyTaxId: "str",
        logo: "http",
        isActive: true,
      };
      if (id) {
        response = await updateCustomer(payload, id);
      } else {
        response = await createCustomer(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Successfully added");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  const onClickAdd = () => {
    openModal();
    setId(null);
    form.resetFields();
    form.setFieldValue("accountLimit", 0);
  };
  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updateCustomer(payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };
  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8 text-left">
          <h2 className="sm:text-xl xxl:text-2xl font-medium flex-1">
            Customer
          </h2>
          <div className="flex items-center justify-end gap-6">
            <ActionAccessWrapper permissionKeys={"CreateCustomer"}>
              <a
                onClick={onClickAdd}
                className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 min-w-36 text-center rounded-full leading-none text-bold bg-redOrange text-white"
              >
                Add Customer
              </a>
            </ActionAccessWrapper>

            <CustomModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              heading={`Add Customer`}
            >
              <AddCustomer
                form={form}
                onFinish={onFinish}
                tier={tier}
                tag={tag}
                gender={gender}
              />
            </CustomModal>
          </div>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find Customer"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar customeDetails-table">
          <CustomTable
            columns={columns}
            endPoint={"supplier/list"}
            search={searchText}
            handleEdit={onClickHandle}
            handleDelete={onClickDelete}
            handleStatus={onClickStatus}
            showActions={true}
            refreshTable={refreshTable}
            permissions={{
              edit: "UpdateCustomerById",
              status: "UpdateCustomerById",
              delete: "DeleteCustomerById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default Customer;
