import Api from "../../network/client";

export const getSalesAmounts = async (query) => {
  return Api.get(`dashboard/sales?${query}`);
};

export const getTopProducts = async (query) => {
  return Api.get(`dashboard/top-product?${query}`);
};

export const getTopOutlet = async () => {
  return Api.get(`dashboard/top-outlets`);
};

export const getCustomerInsights = async () => {
  return Api.get(`dashboard/customer-insights`);
};

export const getInventoryInsights = async () => {
  return Api.get(`dashboard/inventory-insights`);
};
