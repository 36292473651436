import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAllVariantProduct } from "../../../services/product";
import { ServerError } from "../../shared/notification";
import { dropDownPayload } from "../../../utils/helper";
import { getAllListOfValueByKey } from "../../../services/globalService";
import ProductListing from "./productListing";

function InventoryProduct({ form }) {
  const { Option } = Select;
  const [products, setProducts] = useState([]);
  const [discountType, setDiscountType] = useState([]);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      setDiscountType(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  const getVariantProducts = async () => {
    try {
      const {
        data: { data },
      } = await getAllVariantProduct();
      const pros = [];
      data.data.map((record) => {
        let variant = {
          name: `${record.name} / ${record.variant.combination.join(" / ")}`,
          ...record.variant,
        };
        variant.productId = record._id;
        variant.price = record.variant.costPrice;
        pros.push(variant);
      });
      setProducts(pros);
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  useEffect(() => {
    getVariantProducts();
  }, []);

  const onChangeInventoryProduct = (items) => {
    const itemValues = items.map((item) => item.value);
    const tempProduct = products.filter((product) =>
      itemValues.includes(product._id),
    );
    form.setFieldsValue({ transferDetails: tempProduct });
  };
  return (
    <div>
      <br />
      <Form.Item
        name="transferDetailsIds"
        rules={[
          {
            required: true,
            message: "Please input Product",
          },
        ]}
      >
        <Select
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select an option"
          onChange={onChangeInventoryProduct}
          labelInValue
          mode="tags"
        >
          {dropDownPayload(products).map((option) => (
            <Option key={option._id} value={option._id}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <ProductListing form={form} />
    </div>
  );
}

export default InventoryProduct;
