import React, { useState } from "react";
import AddCustomSale from "./addCustomSale";
import CustomModal from "../shared/modal";

function SearchBar({ setSearchText }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="flex items-center justify-start gap-3">
      <div className="relative flex-1">
        <input
          onChange={(e) => setSearchText(e.target.value)}
          type="text"
          placeholder="Find product by name, barcode"
          className="text-sm w-full sm:py-2 xxl:py-3 pl-6 pr-16 rounded-full border border-lightBlue outline-0"
        />
        <div className="absolute right-1.5 top-2/4 -translate-y-2/4 sm:w-[1.8rem] xxl:w-[2.4rem] aspect-square flex items-center justify-center rounded-full bg-brightUFOGreen">
          <svg
            width={22}
            height={15}
            viewBox="0 0 22 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="sm:w-[1rem] xxl:w-[2rem]"
          >
            <path
              d="M10.5305 8.75349C10.4428 8.75349 10.355 8.75349 10.2672 8.66573L0.61428 5.15556C0.263263 5.06781 0 4.71679 0 4.36577C0 4.01476 0.263263 3.66374 0.61428 3.57599L10.2672 0.0658157C10.4428 -0.0219386 10.706 -0.0219386 10.8815 0.0658157L20.5345 3.57599C20.7978 3.66374 21.061 4.01476 21.061 4.36577C21.061 4.71679 20.7978 5.06781 20.4467 5.15556L10.7938 8.66573C10.706 8.75349 10.6183 8.75349 10.5305 8.75349ZM3.42242 4.36577L10.5305 6.91065L17.6386 4.36577L10.5305 1.8209L3.42242 4.36577Z"
              fill="#00E37D"
            />
            <path
              d="M16.6733 14.0187H10.5305C10.004 14.0187 9.65295 13.6677 9.65295 13.1412C9.65295 12.6147 10.004 12.2636 10.5305 12.2636H15.7958C15.708 9.98203 15.1815 8.05144 14.3039 6.64737C14.0407 6.2086 14.2162 5.68207 14.5672 5.41881C15.006 5.15555 15.5325 5.33106 15.7958 5.68207C16.9366 7.52491 17.5508 10.0698 17.5508 13.0534C17.5508 13.6677 17.1998 14.0187 16.6733 14.0187Z"
              fill="#00E37D"
            />
            <path
              d="M10.5305 14.0187H4.38767C3.86115 14.0187 3.51013 13.6677 3.51013 13.1411C3.51013 10.1575 4.12441 7.61261 5.26522 5.76977C5.52848 5.331 6.055 5.24324 6.49378 5.50651C6.93255 5.76977 7.0203 6.29629 6.75704 6.73507C5.8795 8.13913 5.35297 10.0697 5.26522 12.3513H10.5305C11.057 12.3513 11.408 12.7024 11.408 13.2289C11.408 13.7554 11.057 14.0187 10.5305 14.0187ZM19.3059 14.0187C18.7794 14.0187 18.4284 13.6677 18.4284 13.1411V4.3657C18.4284 3.83918 18.7794 3.48816 19.3059 3.48816C19.8324 3.48816 20.1834 3.83918 20.1834 4.3657V13.1411C20.1834 13.6677 19.8324 14.0187 19.3059 14.0187Z"
              fill="#00E37D"
            />
          </svg>
        </div>
      </div>
      <a
        href="#"
        className="block min-w-16 leading-none text-lightGrey font-medium sm:py-2.5 xxl:py-3.5 sm:px-4 xxl:px-6 bg-veryLightGrey border border-lightBlue rounded-full"
      >
        Gift Card
      </a>
      {/* <div>
        <a
          id="customSaleBtn"
          href="#"
          className="block min-w-16 leading-none text-lightGrey font-medium py-3.5 px-6 bg-white border border-lightBlue rounded-full"
          onClick={() => setIsModalOpen(true)}
        >
          Custom Sale
        </a>
        <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
        <CustomModal
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          heading={`Misc. Product`}
        >
          <AddCustomSale />
        </CustomModal>
      </div> */}
    </div>
  );
}

export default SearchBar;
