import React, { useEffect, useState } from "react";
import FilterModal from "../../../component/products/filterModal";
import Filter from "../../../component/products/filter";
import CustomTable from "../../../component/shared/customTable";
import { Link, useNavigate } from "react-router-dom";
import { deleteProductById, updateProduct } from "../../../services/product";
import { Image, message } from "antd";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import moment from "moment";
import { getAllSlugApi } from "../../../services/product/brand";
import { getAllProductTypeApi } from "../../../services/product/productType";
import CustomModal from "../../../component/shared/modal";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";

function Products() {
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [tags, setTags] = useState([]);
  const [brands, setBrands] = useState([]);
  const [season, setSeason] = useState([]);
  const [category, setCategory] = useState([]);
  const [filterValue, setFilterValue] = useState({
    tagIds: "",
    brandIds: "",
    seasonIds: "",
    productTypeIds: "",
    productType: "",
  });

  const onClose = () => {
    setIsFilterOpen(false);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      className: "!p-1",
      render: (_, row) => {
        return (
          <Image
            className="!w-12 aspect-square object-cover rounded-full border border-veryLightGrey p-0.5"
            src={
              row?.images
                ? row?.images[0] || "/img/no-image.png"
                : "/img/no-image.png"
            }
          />
        );
      },
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Product Type",
      dataIndex: "type",
      key: "type",
      render: (_, row) => {
        if (row.type === "NO_VARIANT") {
          return "Single";
        }
        if (row.type === "PRODUCT_WITH_VARIANT") {
          return "Variant";
        }
        if (row.type === "COMPOSITE_PRODUCT") {
          return "Composite";
        }
      },
    },
    {
      title: "SKU",
      dataIndex: "skuNo",
      key: "skuNo",
    },
    {
      title: "Price",
      dataIndex: "brand",
      key: "brand",
      render: (_, row) => {
        return row?.variants[0]?.retailPriceInclTax;
      },
    },
    {
      title: "Units",
      dataIndex: "sku",
      key: "sku",
      render: (_, row) => {
        return row?.sku?.name;
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, row) => {
        return row?.createdBy.fullName;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => {
        return moment(row?.createdAt).format("MMMM Do YYYY hh:mm:ss");
      },
    },
  ];

  const onClickEdit = (row) => {
    navigate("../products/add-product", { state: { id: row._id } });
  };

  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteProductById(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [
          productTypeResponse,
          seasonResponse,
          brandResponse,
          tagResponse,
        ] = await Promise.all([
          getAllProductTypeApi(),
          getAllSlugApi("season"),
          getAllSlugApi("brand"),
          getAllSlugApi("tag"),
        ]);

        if (productTypeResponse.data.meta.success) {
          const d = [{ _id: null, name: "All" }];
          d.push(...productTypeResponse.data.data.data);
          setCategory(d);
        } else {
          openNotification("error", productTypeResponse.data.error);
        }

        if (seasonResponse.data.meta.success) {
          const d = [{ _id: null, name: "All" }];
          d.push(...seasonResponse.data.data.data);
          setSeason(d);
        } else {
          openNotification("error", seasonResponse.data.error);
        }

        if (brandResponse.data.meta.success) {
          const d = [{ _id: null, name: "All" }];
          d.push(...brandResponse.data.data.data);
          setBrands(d);
        } else {
          openNotification("error", brandResponse.data.error);
        }

        if (tagResponse.data.meta.success) {
          const d = [{ _id: null, name: "All" }];
          d.push(...tagResponse.data.data.data);
          setTags(d);
        } else {
          openNotification("error", tagResponse.data.error);
        }
      } catch (error) {
        ServerError();
      }
    };

    fetchAllData();
  }, []);

  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updateProduct(payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };
  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">Products</h2>
          <div className="flex items-center justify-end gap-6">
            {/* <a href="#" className="block text-lightGrey">
              Import
            </a>
            <a href="#" className="inline-flex items-center text-lightGrey">
              Export
              <svg
                className="ml-2"
                width={13}
                height={7}
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.07038 7.73942C7.0785 7.72982 7.08175 7.71702 7.08987 7.70742L12.7624 1.53604C13.0792 1.18883 13.0792 0.625618 12.7624 0.278408C12.7591 0.275208 12.7559 0.273608 12.7526 0.272008C12.6821 0.187934 12.5938 0.120033 12.4939 0.0730505C12.394 0.0260679 12.2849 0.00113714 12.1742 0H0.827556C0.714808 0.00179399 0.603836 0.0279444 0.502478 0.0766044C0.40112 0.125264 0.311849 0.195247 0.240971 0.281608L0.237722 0.278408C0.084381 0.45311 0 0.676313 0 0.907226C0 1.13814 0.084381 1.36134 0.237722 1.53604L5.92321 7.73942C5.99399 7.82112 6.08192 7.88673 6.18095 7.93172C6.27997 7.9767 6.38773 8 6.49679 8C6.60585 8 6.71361 7.9767 6.81263 7.93172C6.91166 7.88673 6.9996 7.82112 7.07038 7.73942Z"
                  fill="#BABABA"
                />
              </svg>
            </a> */}
            <ActionAccessWrapper permissionKeys={"CreateProduct"}>
              <Link to="/products/add-product">
                <a
                  href="#"
                  className="block capitalize sm:py-2.5 xxl:py-4 sm:px-4 xxl:px-6 sm:min-w-28 xxl:min-w-36 text-center rounded-full leading-none text-bold bg-redOrange text-white"
                >
                  Add Products
                </a>
              </Link>
            </ActionAccessWrapper>
          </div>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find product by name, barcode"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
          <button
            type="button"
            onClick={() => setIsFilterOpen(true)}
            className="productFilterBtn absolute right-0 top-0 bg-veryLightGrey h-full rounded-r-full flex items-center justify-center min-w-32 border border-lightBlue text-lightGrey font-medium [&.active]:bg-redOrange [&.active]:text-white [&.active]:border-redOrange"
          >
            More Filters
          </button>
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          {/* <FilterModal isModalOpen={isFilterOpen} onClose={onClose}> */}
          <CustomModal isModalOpen={isFilterOpen} closeModal={onClose}>
            <Filter
              tags={tags}
              brands={brands}
              season={season}
              category={category}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          </CustomModal>
        </form>
        {/* Records */}
        <div className="flex-1 overflow-y-scroll scrollbar productslisting-table">
          <CustomTable
            columns={columns}
            endPoint={"product/list"}
            search={searchText}
            handleEdit={onClickEdit}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            showActions={true}
            refreshTable={refreshTable}
            filters={filterValue}
            permissions={{
              edit: "UpdateProductById",
              status: "UpdateProductById",
              delete: "DeleteProductById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default Products;
