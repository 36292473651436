import React, { useState } from "react";
import CustomModal from "../../component/shared/modal";
import SearchInput from "../../component/shared/searchInput";
import CustomTable from "../../component/shared/customTable";
import AddBrands from "../../component/products/addBrands";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { Form, message } from "antd";
import {
  createSlug,
  deleteslug,
  updateSlug,
} from "../../services/product/brand";
import ActionAccessWrapper from "../../component/shared/actionAccessWrapper";

function Tag({ type, heading }) {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [id, setId] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];
  const onFinish = async (values) => {
    let response;

    try {
      const payload = {
        ...values,
        accountLimit: Number(values.accountLimit),
        isActive: true,
      };
      if (id) {
        response = await updateSlug(type, payload, id);
      } else {
        response = await createSlug(type, payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Tag successfully saved");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteslug(type, row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onClickHandle = (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };
  const onClickAdd = () => {
    openModal();
    form.resetFields();
  };

  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updateSlug(type, payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8 text-left">
          <h2 className="sm:text-xl xxl:text-2xl font-medium flex-1">
            {heading}
          </h2>
          <ActionAccessWrapper permissionKeys={"CreateCustomerTag"}>
            <a
              onClick={onClickAdd}
              id="addProductTypeBtn"
              className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add {heading}
            </a>
          </ActionAccessWrapper>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={`Add ${heading}`}
          >
            <AddBrands form={form} onFinish={onFinish} />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between tagsDetail-table overflow-auto scrollbar">
          <CustomTable
            columns={columns}
            endPoint={`product-slug/${type}`}
            refreshTable={refreshTable}
            search={searchText}
            handleEdit={onClickHandle}
            handleDelete={onClickDelete}
            handleStatus={onClickStatus}
            showActions={true}
            permissions={{
              edit: "UpdateCustomerTagById",
              status: "UpdateCustomerTagById",
              delete: "DeleteCustomerTagById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default Tag;
