import React, { useState } from "react";
import { Form, Input, Button, Upload, Row, Col, message, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CustomSelect from "../shared/customSelect/indes";
import { API_BASE_URL } from "../shared/contants";

function AddProductType({
  form,
  onFinish,
  parent,
  uploadedUrl,
  setUploadedUrl,
}) {
  const [loading, setLoading] = useState(false);

  const props = {
    name: "file",
    action: `${API_BASE_URL}/file/multi/productType`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        form.setFieldsValue({
          image: info.file.response.data.file,
        });
        setUploadedUrl(info.file.response.data.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          {/* Left Side Form Fields */}
          <Col span={16}>
            <Form.Item
              name="name"
              label={<span className="text-lightGrey font-medium">Name</span>}
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="Enter Category Name"
              />
            </Form.Item>

            {/* Parent Type and Display Order */}
            <Form.Item
              name="parentId"
              label={
                <span className="text-lightGrey font-medium">Parent </span>
              }
            >
              <CustomSelect options={parent} />
            </Form.Item>

            <Form.Item
              name="sortOrder"
              label={
                <span className="text-lightGrey font-medium">
                  Display Order
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="number"
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="Enter display order"
              />
            </Form.Item>

            {/* Title and Slug */}
            {/* <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="title"
                                    label={<span className="text-lightGrey font-medium">Title</span>}
                                    rules={[{ required: true, message: 'This is required' }]}

                                >
                                    <Input
                                        className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                                        placeholder="Enter title"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="slug"
                                    label={<span className="text-lightGrey font-medium">Slug</span>}
                                    rules={[{ required: true, message: 'This is required' }]}

                                >
                                    <Input
                                        className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                                        placeholder="Enter slug"
                                    />
                                </Form.Item>
                            </Col>
                        </Row> */}

            {/* Description */}
            <Form.Item
              name="description"
              label={
                <span className="text-lightGrey font-medium">Description</span>
              }
            >
              <Input.TextArea
                rows={3}
                className="block w-full border focus:border-ufoGreen rounded-lg py-3.5 px-6"
                placeholder="Enter description"
              />
            </Form.Item>
          </Col>

          {/* Right Side Image Upload */}
          <Col span={8}>
            <Form.Item name="image" label="Category Image">
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                {...props}
                // beforeUpload={beforeUpload}
                // onChange={handleChange}
              >
                {uploadedUrl ? (
                  <img
                    src={uploadedUrl}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddProductType;
