import { useSelector } from "react-redux";
import { getUserPermissions } from "../../../redux/feature/authSlice";

const ActionAccessWrapper = ({ permissionKeys, children }) => {
  const allowedPermissionFromStore = useSelector(getUserPermissions) || {};
  if (allowedPermissionFromStore.masterUser == true) {
    return children;
  } else {
    const isValid = allowedPermissionFromStore?.permissions?.some(
      (data) => data?.key == permissionKeys,
    );

    if (isValid) return children;
    return <></>;
  }
};

export default ActionAccessWrapper;
