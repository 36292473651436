import React from "react";
import CustomModal from "../shared/modal";
import AddProductToProcrss from "./addProductToProcrss";

function ProductListing({
  products,
  handleAddSelectedProduct,
  onClickCloseCombination,
  combinationModal,
  form,
  onFinishListingModal,
  selecectedAttribute,
  tempAttibute,
}) {
  return (
    <div className="grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-6 gap-3">
      {products.map((data, index) => {
        return (
          <a
            key={index + 1}
            onClick={() => handleAddSelectedProduct(data)}
            className="productSaleItem bg-white rounded-2xl border border-veryLightGrey cursor-pointer overflow-hidden flex flex-col"
          >
            <img
              src={
                data?.images?.length
                  ? data.images[0] || "/img/no-image.png"
                  : "/img/no-image.png"
              }
              alt=""
              width=""
              height=""
              className="w-full aspect-[1/0.92] object-contain bg-brightRedOrange rounded-2xl p-4"
            />
            <div className="px-3 mt-3 text-center sm:text-sm xxl:text-lg font-medium text-md mb-3 flex items-center justify-center flex-1">
              <span class="line-clamp-2">{data.name}</span>
            </div>
            {/* Product Popup */}
            <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          </a>
        );
      })}

      <CustomModal
        isModalOpen={combinationModal}
        closeModal={onClickCloseCombination}
        heading={form.getFieldValue("name")}
      >
        <AddProductToProcrss
          form={form}
          onFinishListingModal={onFinishListingModal}
          selecectedAttribute={selecectedAttribute}
          tempAttibute={tempAttibute}
        />
      </CustomModal>
    </div>
  );
}

export default ProductListing;
