import React, { useState } from "react";
import FilterModal from "../../../component/products/filterModal";
import { useNavigate } from "react-router-dom";
import SalesFilter from "../../../component/pos/salesHistory/salesFilter";
import SaleTable from "./table";

function POS() {
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onClose = () => {
    setIsFilterOpen(false);
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_, row) => <p>{new Date(row.orderDate).toDateString()}</p>,
    },
    {
      title: "Sale Type",
      render: (_, row) => {
        return row?.salesType?.toUpperCase() || "SALES";
      },
    },
    {
      title: "Customer",
      render: (_, row) => {
        return row?.customer?.fullName || row?.customerName;
      },
    },
    {
      title: "Payment Type",
      render: (_, row) => {
        const a = ["Cash", "Card", "Loyality"];
        if (row.receivedCash <= 0) {
          a.splice(a.indexOf("Cash"), 1);
        }
        if (row.receivedCredit <= 0) {
          a.splice(a.indexOf("Card"), 1);
        }
        if (row.receivedLoyality <= 0) {
          a.splice(a.indexOf("Loyality"), 1);
        }

        return a.join(", ");
      },
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Sale Total",
      dataIndex: "subTotal",
      key: "subTotal",
      render: (_, row) => {
        return Number(row?.subTotal).toFixed(2);
      },
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (_, row) => {
        return Number(row?.discountAmount).toFixed(2);
      },
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (_, row) => {
        return Number(row?.tax).toFixed(2);
      },
    },
    {
      title: "Remaining Balance",
      dataIndex: "remainingBalance",
      key: "remainingBalance",
      render: (_, row) => {
        return Number(row?.remainingBalance).toFixed(2);
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, row) => {
        return row?.createdBy?.fullName;
      },
    },
    {
      title: "Modified By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (_, row) => {
        return row?.updatedBy?.fullName;
      },
    },
  ];

  const onClickEdit = (row) => {
    navigate(`../../pos/process-sales?id=${row._id}`, {
      state: { id: row._id },
    });
  };
  const onClickRefund = (row) => {
    navigate(`../../pos/process-sales?id=${row._id}?type=refund`, {
      state: { id: row._id, type: "refund" },
    });
  };

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">Sales History</h2>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find product by name, barcode"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
          {/* <button
            type="button"
            onClick={() => setIsFilterOpen(true)}
            className="productFilterBtn absolute right-0 top-0 bg-veryLightGrey h-full rounded-r-full flex items-center justify-center min-w-32 border border-lightBlue text-lightGrey font-medium [&.active]:bg-redOrange [&.active]:text-white [&.active]:border-redOrange"
          >
            More Filters
          </button> */}
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          <FilterModal isModalOpen={isFilterOpen} onClose={onClose}>
            <SalesFilter />
          </FilterModal>
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between saleHistory-table overflow-auto scrollbar">
          <SaleTable
            columns={columns}
            endPoint={"sales/list"}
            search={searchText}
            handleEdit={onClickEdit}
            handleRefund={onClickRefund}
            showActions={true}
            permissions={{
              edit: "UpdateSalesById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default POS;
