import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../shared/sidebar";
import Header from "../../shared/header";

const PrivateLayout = () => {
  return (
    <>
      <body class="bg-lightBlue">
        <div id="main" class="p-2 xl:p-3 xxl:p-5 flex gap-4 items-start h-dvh">
          <SideBar />
          <div
            id="main-panel"
            class="flex-1 flex flex-col h-full overflow-hidden"
          >
            <Header />
            <Outlet />
          </div>
        </div>
      </body>
    </>
  );
};

export default PrivateLayout;
