import { Button, Form, Input } from "antd";
import React from "react";

function AddTax({ form, onFinish }) {
  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={<span className="text-lightGrey font-medium">Name</span>}
          rules={[{ required: true, message: "This is required" }]}
        >
          <Input
            className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
            placeholder="Enter Brand Name"
          />
        </Form.Item>

        <Form.Item
          name="rate"
          label={<span className="text-lightGrey font-medium">Rate %</span>}
          rules={[{ required: true, message: "This is required" }]}
        >
          <Input
            type="number"
            className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
            placeholder="Enter Rate"
          />
        </Form.Item>

        <div className="flex items-center justify-end gap-3 mt-10">
          <div className="flex items-center justify-end gap-3">
            <Button
              type="primary"
              htmlType="submit"
              className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default AddTax;
