import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux/feature/authSlice";
import {
  CalculateTotalDiscountOfSales,
  CalculateTotalPrice,
  CalculateTotalPriceWithoutDiscountAndTax,
  CalculateTotalTax,
} from "../../../utils/helper";
import { getSelectedOutlet } from "../../../redux/feature/outletSlice";

const Invoice = ({
  invoiceRef,
  selectedProductState,
  discountTypes,
  form,
  invoiceData,
}) => {
  const { user } = useSelector(getUserDetails) || {};
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  function formatDate() {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  return (
    <div id="invoice" ref={invoiceRef}>
      <>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Tax Invoice</title>
        <table
          style={{
            width: 700,
            maxWidth: "100%",
            margin: "15px auto 40px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <p
                style={{ textAlign: "left", fontSize: 16, fontWeight: "light" }}
              >
                {selectedOutlet?.name}
              </p>
            </tr>
            <tr>
              <p
                style={{ textAlign: "left", fontSize: 16, fontWeight: "light" }}
              >
                {selectedOutlet?.phone}
              </p>
            </tr>
            <tr>
              <p
                style={{ textAlign: "left", fontSize: 16, fontWeight: "light" }}
              >
                {`${selectedOutlet?.state}, ${selectedOutlet?.city}, ${selectedOutlet?.street}`}
              </p>
            </tr>
          </thead>
        </table>
        <table
          style={{
            width: 700,
            maxWidth: "100%",
            margin: "15px auto 30px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  padding: "2px 0",
                  fontSize: 22,
                  textTransform: "uppercase",
                }}
              >
                Tax Invoice/Receipt
              </th>
            </tr>
            <tr>
              <td
                style={{ textAlign: "center", padding: "2px 0", fontSize: 13 }}
              >
                Invoice# <div id="invoice-number"></div>
              </td>
            </tr>
            <tr>
              <td
                style={{ textAlign: "center", padding: "2px 0", fontSize: 13 }}
              >
                {formatDate()}
              </td>
            </tr>
          </thead>
        </table>
        <table
          style={{
            width: 700,
            maxWidth: "100%",
            margin: "15px auto 0",
            borderCollapse: "collapse",
            borderBottom: "2px solid #000",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  fontSize: 13,
                  padding: "8px 0",
                  borderBottom: "2px solid #000",
                }}
                colSpan={2}
              >
                Item
              </th>
              <th
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 0",
                  borderBottom: "2px solid #000",
                }}
              >
                Sales (inc. tax)
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedProductState.map((data) => {
              return (
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      fontSize: 13,
                      padding: "8px 4px",
                      borderBottom: "1px solid #e1e1e1",
                    }}
                    colSpan={2}
                  >
                    <strong>{data?.variants[0]?.quantity}</strong> X{" "}
                    {data?.name}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      fontSize: 13,
                      padding: "8px 4px",
                      borderBottom: "1px solid #e1e1e1",
                    }}
                  >
                    {data?.variants[0]?.retailPrice}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  width: "50%",
                }}
              />
              <td
                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px" }}
              >
                Discount
              </td>
              <td
                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px" }}
              >
                {CalculateTotalDiscountOfSales(
                  selectedProductState,
                  discountTypes,
                  form,
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  width: "50%",
                }}
              />
              <td
                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px" }}
              >
                Subtotal
              </td>
              <td
                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px" }}
              >
                {CalculateTotalPriceWithoutDiscountAndTax(selectedProductState)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  width: "50%",
                }}
              />
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  borderBottom: "2px solid #000",
                }}
              >
                Tax
              </td>
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  borderBottom: "2px solid #000",
                }}
              >
                {CalculateTotalTax(selectedProductState)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  width: "50%",
                }}
              />
              <td
                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px" }}
              >
                <strong>Total</strong>
              </td>
              <td
                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px" }}
              >
                <strong>
                  {CalculateTotalPrice(
                    selectedProductState,
                    discountTypes,
                    form,
                  )}
                </strong>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  width: "50%",
                }}
              />
              {invoiceData?.receivedCash == 0 ? null : (
                <>
                  <td
                    style={{
                      textAlign: "right",
                      fontSize: 13,
                      padding: "8px 4px",
                    }}
                  >
                    Cash
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      fontSize: 13,
                      padding: "8px 4px",
                    }}
                  >
                    {invoiceData?.receivedCash}
                  </td>
                </>
              )}
            </tr>
            {invoiceData?.receivedCredit == 0 ? null : (
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    width: "50%",
                  }}
                />
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                  }}
                >
                  Card
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                  }}
                >
                  {invoiceData?.receivedCredit}
                </td>
              </tr>
            )}

            {invoiceData?.receivedLoyality == 0 ? null : (
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                    width: "50%",
                  }}
                />
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                  }}
                >
                  loyality
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    padding: "8px 4px",
                  }}
                >
                  {invoiceData?.receivedLoyality}
                </td>
              </tr>
            )}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  width: "50%",
                }}
              />
              <td
                style={{
                  textAlign: "right",
                  fontSize: 13,
                  padding: "8px 4px",
                  borderBottom: "2px solid #000",
                }}
                colSpan={2}
              >
                {/* (Tendered: Rs500.00, Change : Rs244.80) */}
              </td>
            </tr>
            {/* <tr>
                            <td
                                style={{
                                    textAlign: "right",
                                    fontSize: 13,
                                    padding: "8px 4px 22px",
                                    width: "50%"
                                }}
                            />
                            <td
                                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px 22px" }}
                            >
                                <small>
                                    <strong>Outstanding</strong>
                                </small>
                            </td>
                            <td
                                style={{ textAlign: "right", fontSize: 13, padding: "8px 4px 22px" }}
                            >
                                <strong>Rs0.00</strong>
                            </td>
                        </tr> */}
          </tfoot>
        </table>
        <table
          style={{
            width: 700,
            maxWidth: "100%",
            margin: "8px auto 15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: 13,
                  padding: "15px 0 0",
                }}
              >
                {/* <img src="barcode.jpg" width={140} /> */}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  padding: "0 0 8px",
                }}
              >
                {user?.fullName}
                <br /> {formatDate()}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  padding: "0 0 8px",
                }}
              >
                Thank you for trialling <br /> Poxfy point of sale
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontSize: 11,
                  padding: "0 0 8px",
                }}
              >
                {/* <img src="logo.png" width={72} /> <br /> */}
                <a href="#" style={{ textDecoration: "none", color: "#000" }}>
                  poxfy.com
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
};

export default Invoice;
