import React, { useState } from "react";
import { Form, Input, Row, Col, Button, InputNumber } from "antd";

function AddLoyality({ form, onFinish }) {
  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name={["loyality", "customerSignUpPoints"]}
              label={
                <span className="text-lightGrey font-medium">
                  Signup Points
                </span>
              }
              rules={[{ required: true, message: "Signup points is required" }]}
            >
              <InputNumber
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-1 xxl:py-2 sm:px-4 xxl:px-6"
                placeholder="Enter Signup Points"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["loyality", "saleAmount"]}
              label={
                <span className="text-lightGrey font-medium">Sale Amount</span>
              }
              rules={[{ required: true, message: "Sale amount is required" }]}
            >
              <InputNumber
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="Enter Sale Amount"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["loyality", "points"]}
              label={<span className="text-lightGrey font-medium">Points</span>}
              rules={[{ required: true, message: "Points is required" }]}
            >
              <InputNumber
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="Enter Points"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex items-center justify-end">
          <div className="flex items-center justify-center gap-3">
            <Button
              type="primary"
              htmlType="submit"
              className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default AddLoyality;
