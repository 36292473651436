import React, { useEffect, useRef, useState } from "react";
import { getSelectedOutlet } from "../../redux/feature/outletSlice";
import { useSelector } from "react-redux";
import { openNotification, ServerError } from "../shared/notification";
import { createSales, updatedSales } from "../../services/sales";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import {
  CalculateTotalDiscountOfSales,
  CalculateTotalPrice,
  CalculateTotalPriceWithoutDiscountAndTax,
  CalculateTotalTax,
} from "../../utils/helper";
import { getAllListOfValueByKey } from "../../services/globalService";
import Invoice from "../../pages/pos/invoice";

function Payment({
  selectedProductState,
  isModalOpen,
  customerID,
  customerName,
  customerContactNo,
  notes,
  loader,
  billPaymentModal,
  paymentStatus,
  allOverDiscount,
  form,
  discountTypes,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const invoiceRef = useRef();
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const [totalPriceIncTax, setRetailPriceIncTax] = useState(0);
  const [tempAmt, setTempAmt] = useState(0);
  const [receivedCash, setReceivedCash] = useState(0);
  const [receivedCredit, setReceivedCredit] = useState(0);
  const [receivedLoyality, setReceivedLoyality] = useState(0);
  const [saleComplete, setSaleComplete] = useState(false);
  const [id, setId] = useState(location.state?.id ? location?.state?.id : null);
  const salesType = location?.state?.type;
  const [percentageDiscountType, setPercentageDiscountType] = useState({});
  const [invoiceData, setInvoiceData] = useState({});

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      const percentageDiscountTypeVal = response.data.data.data.find(
        (data) => data.key == "percentage",
      );
      setPercentageDiscountType(percentageDiscountTypeVal);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  const generateQuickSelection = (billAmount) => {
    // Define percentages for quick selections
    const percentages = [0.2, 0.4, 0.6, 0.8, 1.0, 1.2];

    // Calculate quick selection amounts
    const quickSelections = percentages.map((percent) =>
      Math.round(billAmount * percent),
    );

    return quickSelections;
  };

  useEffect(() => {
    const retailPrice = selectedProductState.reduce((total, product) => {
      return (
        total +
        product.variants.reduce(
          (sum, variant) => sum + Number(variant.retailPrice),
          0,
        )
      );
    }, 0);
    const am = CalculateTotalPrice(selectedProductState, discountTypes, form);
    setRetailPriceIncTax(+am);
    setTempAmt(
      +(am - receivedCash - receivedCredit - receivedLoyality).toFixed(2),
    );
  }, [selectedProductState, isModalOpen, loader, billPaymentModal]);

  useEffect(() => {
    const am = (
      totalPriceIncTax -
      receivedCash -
      receivedCredit -
      receivedLoyality
    ).toFixed(2);
    setTempAmt(+am);
  }, [receivedCash, receivedCredit, receivedLoyality]);

  useEffect(() => {
    if (saleComplete == true) {
      onClickSubmit("CARD");
    }
  }, [saleComplete]);

  const onClickPaymentBtn = async (type) => {
    if (type === "cash") {
      setReceivedCash(receivedCash + +tempAmt);
    } else if (type === "card") {
      setReceivedCredit(receivedCredit + +tempAmt);
    } else if (type === "loyality") {
      setReceivedLoyality(receivedLoyality + +tempAmt);
    }
    if (
      totalPriceIncTax -
        (receivedCash + receivedCredit + receivedLoyality + tempAmt) <=
      0
    ) {
      setSaleComplete(true);
    }
  };

  const downloadPDF = async (data) => {
    const invoice = document.getElementById("invoice");
    const invoiceDiv = document.getElementById("invoice-number");
    invoiceDiv.textContent = data?.orderId;
    const originalBody = document.body.innerHTML;
    document.body.innerHTML = invoice.outerHTML;
    window.print();
    document.body.innerHTML = originalBody;
    window.location.href = "../pos/sale-history";
  };
  const onClickSubmit = async (paymentType) => {
    let updatedAllOverDiscount = {};
    if (!allOverDiscount.discount) {
      updatedAllOverDiscount.discount = 0;
      updatedAllOverDiscount.discountTypeId = percentageDiscountType._id;
    } else {
      updatedAllOverDiscount = allOverDiscount;
    }
    let allVariants;
    if (!id) {
      allVariants = selectedProductState.flatMap((product) =>
        product.variants.map((variant) => ({
          ...variant,
          productName: product.name,
          productId: product._id,
          quantity: variant.quantity ? Number(variant.quantity) : 1,
          total: 100,
          price: variant.retailPrice,
        })),
      );
    } else {
      allVariants = selectedProductState.flatMap((product) =>
        product.variants.map((variant) => ({
          ...variant,
          productName: product.name,
          productId: variant.productId,
          quantity: variant.quantity ? Number(variant.quantity) : 1,
          total: 100,
          price: variant.retailPrice,
        })),
      );
    }

    const a = allVariants.map((data) => {
      return {
        ...data,
        discountType: data.discountTypeId,
      };
    });

    const payload = {
      ...updatedAllOverDiscount,
      outletId: selectedOutlet._id,
      orderDate: new Date(),
      customerId: customerID,
      customerName: customerName,
      customerContactNo: customerContactNo,
      paymentStatus: "PAID",
      paymentType,
      notes: notes,
      saleDetails: a,
      receivedCash: Number(receivedCash),
      receivedCredit: Number(receivedCredit),
      receivedLoyality: Number(receivedLoyality),
      surcharge: 0,
    };
    try {
      if (id && location?.state?.type !== "refund") {
        payload.salesType = "sales";
        const response = await updatedSales(payload, id);
        if (response.data.meta.success) {
          navigate("../pos/sale-history");
          openNotification("success", "Sales successfully saved");
        } else {
          openNotification("error", response.data.error);
        }
      } else if (id && location?.state?.type == "refund") {
        payload.salesType = "refund";
        payload.paymentType = "CASH";
        const response = await createSales(payload);
        if (response.data.meta.success) {
          navigate("../pos/sale-history");
          openNotification("success", "Sales successfully saved");
        } else {
          openNotification("error", response.data.error);
        }
      } else {
        payload.salesType = "sales";
        setInvoiceData(payload);
        const response = await createSales(payload);
        if (response.data.meta.success) {
          navigate("../pos/sale-history");
          downloadPDF(response.data.data);
          openNotification("success", "Sales successfully saved");
        } else {
          openNotification("error", response.data.error);
        }
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <Invoice
          invoiceRef={invoiceRef}
          selectedProductState={selectedProductState}
          discountTypes={discountTypes}
          form={form}
          invoiceData={invoiceData}
        />
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-5 mr-5 ml-5 mb-5 mt-0">
          <h3 className="text-left text-lg text-ufoGreen font-medium border-y border-lightGrey py-3">
            Sale Summary
          </h3>
          <table className="mt-2 w-full">
            <tbody>
              <tr>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 flex justify-start">
                  Sub Total
                </td>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 text-right">
                  {CalculateTotalPriceWithoutDiscountAndTax(
                    selectedProductState,
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 flex justify-start">
                  Discount
                </td>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 text-right">
                  {CalculateTotalDiscountOfSales(
                    selectedProductState,
                    discountTypes,
                    form,
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 flex justify-start">
                  Tax
                </td>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 text-right">
                  {CalculateTotalTax(selectedProductState)}
                </td>
              </tr>
              <tr>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 flex justify-start">
                  Grand Total
                </td>
                <td className="text-lightGrey text-sm border-b border-lightBlue font-medium py-2.5 text-right">
                  {CalculateTotalPrice(
                    selectedProductState,
                    discountTypes,
                    form,
                  )}
                </td>
              </tr>
              <tr>
                <td className="py-2 text-left border-lightGrey text-lg font-medium">
                  After Discount:
                </td>
                <td className="py-2 border-lightGrey text-lg font-medium w-24">
                  {CalculateTotalPrice(
                    selectedProductState,
                    discountTypes,
                    form,
                  )}
                </td>
              </tr>
              {receivedCash > 0 && (
                <tr>
                  <td className="text-left py-2 border-y font-bold">
                    Cash Received:
                  </td>
                  <td className="py-2 border-y font-bold w-24">
                    {receivedCash}
                  </td>
                </tr>
              )}
              {receivedCredit > 0 && (
                <tr>
                  <td className="text-left py-2 border-y font-bold">
                    Credit Received:
                  </td>
                  <td className="py-2 border-y font-bold w-24">
                    {receivedCredit}
                  </td>
                </tr>
              )}
              {receivedLoyality > 0 && (
                <tr>
                  <td className="text-left py-2 border-y font-bold">
                    Loyality Received:
                  </td>
                  <td className="py-2 border-y font-bold w-24">
                    {receivedLoyality}
                  </td>
                </tr>
              )}
              <tr>
                <td className="text-left py-2 border-y text-xl font-bold">
                  To Pay:
                </td>
                <td className="py-2 text-xl border-y font-bold w-24">
                  {(
                    totalPriceIncTax -
                    receivedCash -
                    receivedCredit -
                    receivedLoyality
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-span-7 border border-lightGrey rounded-3xl rounded-tl-none px-5 pt-5 pb-10">
          <div>
            <label className="block text-left">
              <span className="block text-sm font-medium mb-4">
                Amount Tendered
              </span>
              <input
                type="text"
                onChange={(e) => setTempAmt(e.target.value)}
                value={tempAmt}
                className="block w-full text-5xl font-medium leading-none py-3 text-center border border-lightGrey rounded-lg"
              />
            </label>
            {/* <div className="flex flex-wrap items-center justify-start mt-4 gap-3.5">
              {generateQuickSelection(totalPriceIncTax).map((val) => {
                return (
                  <div
                    onClick={() => setRetailPriceIncTax(val)}
                    className="cursor-pointer text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none"
                  >
                    {val}
                  </div>
                );
              })}
            </div> */}
            <div className="flex items-center justify-center gap-3 mt-6">
              <Button
                type="primary"
                onClick={() => onClickPaymentBtn("card")}
                htmlType="button"
                className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
              >
                Credit / Debit Card
              </Button>
              <Button
                type="primary"
                onClick={() => onClickPaymentBtn("cash")}
                htmlType="button"
                className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
              >
                Cash
              </Button>
              {customerID && customerID.length && (
                <Button
                  type="primary"
                  onClick={() => onClickPaymentBtn("loyality")}
                  htmlType="button"
                  className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
                >
                  Loyality
                </Button>
              )}
              {/* <input
                type="submit"
                defaultValue="Card"
                className="cursor-pointer leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
              />
              <input
                
                type="submit"
                defaultValue="Cash"
                className="cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
              /> */}
            </div>
            {/* <label className="block mt-10">
              <input
                type="text"
                defaultValue="Walk In"
                className="block w-full text-3xl leading-none px-5 py-1.5 border border-lightGrey rounded-lg"
              />
            </label> */}
            {/* <div className="flex flex-wrap items-center justify-start mt-4 gap-3.5">
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                Store Credit
              </div>
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                On Account
              </div>
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                layby
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
