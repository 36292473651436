import { Form, Input } from "antd";
import React, { useState, useEffect } from "react";
import CustomSelect from "../../shared/customSelect/indes";
import { dropDownPayload, dropDownPayloadLov } from "../../../utils/helper";
import { barcodeHints, validationRules } from "./validator";

function Information({ unit, barcodeTypes, form }) {
  const [barcodeType, setBarCode] = useState("");

  useEffect(() => {
    if (form && barcodeTypes?.length) {
      const barcodeId = form.getFieldsValue();
      const barcodeRecord = barcodeTypes?.find(
        (data) => data._id == barcodeId?.barcodeTypeId,
      );
      setBarCode(barcodeRecord?.value);
    }
  }, [form, barcodeTypes]);

  return (
    <>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please input the product name!" }]}
      >
        <Input
          placeholder="Product Name"
          className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full sm:mt-4 xxl:mt-6 mb-4 outline-0"
        />
      </Form.Item>
      {/* SKU and Barcode Fields */}
      <div className="grid grid-cols-3 gap-4">
        <Form.Item
          name="skuNo"
          rules={[{ required: false, message: "Please input the sku number!" }]}
        >
          <Input
            type="text"
            placeholder="SKU Number"
            className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 mb-0 border border-lightGrey w-full rounded-full outline-0"
          />
        </Form.Item>
        <Form.Item
          name="reOrderLevel"
          rules={[
            { required: true, message: "Please input the re-order level!" },
          ]}
        >
          <Input
            type="number"
            placeholder="Re-Order Level"
            className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 mb-0 border border-lightGrey w-full rounded-full outline-0"
          />
        </Form.Item>
        <Form.Item
          name="skuId"
          rules={[{ required: true, message: "Please input the unit!" }]}
        >
          <CustomSelect
            options={dropDownPayload(unit)}
            placeholder={"Select Unit"}
          />
          {/* unit */}
        </Form.Item>

        <Form.Item
          name="barcodeTypeId"
          rules={[
            { required: true, message: "Please input the barcode type!" },
          ]}
        >
          <CustomSelect
            onChange={(value, option) => {
              setBarCode(option?.label);
              form.setFieldsValue({ barcode: "" });
            }}
            options={dropDownPayloadLov(barcodeTypes)}
            placeholder={"Select Barcode Type"}
          />
        </Form.Item>

        <Form.Item
          name="barcode"
          rules={[
            { required: true, message: "Please input the barcode!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const selectedBarcodeType = getFieldValue("barcodeTypeId");
                if (!selectedBarcodeType) {
                  return Promise.reject(
                    new Error("Please select a barcode type first!"),
                  );
                }
                const regex = validationRules[barcodeType];
                if (regex && regex.test(value)) {
                  return Promise.resolve(); // Valid barcode
                } else {
                  return Promise.reject(
                    new Error(
                      `Invalid barcode format for the selected type (${barcodeHints[barcodeType]})!`,
                    ),
                  );
                }
              },
            }),
          ]}
        >
          <Input
            placeholder="Barcode"
            className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 mb-0 border border-lightGrey w-full rounded-full outline-0"
          />
        </Form.Item>
      </div>
      <div className="">
        <Form.Item
          name="description"
          label={<span className="text-sm text-lightGrey">Description</span>}
        >
          <Input.TextArea
            rows={5}
            className="border border-lightGrey rounded-2xl resize-none py-3 px-5"
            placeholder="Enter your description here"
          />
        </Form.Item>
      </div>
    </>
  );
}

export default Information;
