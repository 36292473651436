import React from "react";

const InfoCardDashboard = ({
  mainheading,
  todayLabel,
  todayValue,
  yesterdayLabel,
  yesterdayValue,
  imgSrc,
  arrowSvg,
  svgIcon,
  svgIconClassName,
  svgIconBottom,
}) => {
  return (
    <div className="col-span-6 xlg:col-span-3 bg-white rounded-3xl relative overflow-hidden">
      <div className="flex items-start justify-start pl-5 pr-5 pt-2 pb-3">
        <div className="uppercase text-[1rem]">{mainheading}</div>
      </div>
      {/* Background Image */}
      {/* <img
        src={imgSrc}
        alt="background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      /> */}

      {/* Main Content */}
      <div className="relative flex flex-wrap justify-between h-full">
        <div className="pl-5 pr-5 pb-5 pt-2">
          {/* Today's Data */}
          <div className="mb-4 last:mb-0">
            <div className="uppercase text-[0.7rem]">{todayLabel}</div>
            <div className="font-bold text-[1.6rem] leading-7">
              {todayValue}
            </div>
          </div>

          {/* Yesterday's Data */}
          <div className="mb-4 last:mb-0">
            <div className="uppercase text-[0.7rem]">{yesterdayLabel}</div>
            <div className="font-bold text-[1.6rem] leading-7">
              {yesterdayValue}
            </div>
          </div>
        </div>

        {/* Icon with SVG */}
        <div className="relative w-28 pl-5 pr-5 pb-5 pt-2">
          <div className={svgIconClassName}>
            {svgIcon}
            {arrowSvg}
          </div>
        </div>

        {/* SVG at the bottom */}
        <div className="w-full relative self-end">{svgIconBottom}</div>
      </div>
    </div>
  );
};

export default InfoCardDashboard;
