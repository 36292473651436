import React, { useEffect, useState } from "react";
import {
  changeUserStatus,
  createUser,
  deleteUserApi,
  getAllUserApi,
  updateUser,
} from "../../services/user";
import { Form, Image } from "antd";
import CustomModal from "../../component/shared/modal";
import SearchInput from "../../component/shared/searchInput";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import AddUser from "../../component/user/addUser";
import UserTable from "../../component/user/userTable";
import { getAllRoleApi } from "../../services/user/role";
import ActionAccessWrapper from "../../component/shared/actionAccessWrapper";

function User() {
  const [searchText, setSearchText] = useState("");
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [role, setRole] = useState([]);
  const [form] = Form.useForm();

  const getAllRole = async () => {
    try {
      const { data: response } = await getAllRoleApi();
      const { data: record } = response;
      console.log(record);
      const tempData = record.data.map((record) => ({
        label: record.name,
        value: record._id,
      }));
      setRole(tempData);
    } catch {
      return ServerError();
    }
  };

  useEffect(() => {
    getAllRole();
  }, []);

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeUserStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteUserApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateUser(payload, id);
      } else {
        response = await createUser(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "User successfully saved");
        setId(null);
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onClickEdit = async (row) => {
    setId(row._id);
    setUploadedUrl(row.avatar);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contactNo",
      key: "contactNo",
    },
    {
      title: "Outlets",
      dataIndex: "outlets",
      key: "outlets",
      render: (_, row) => <p>{row.outlets?.map((x) => x.name).join(", ")}</p>,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (_, row) => <p>{row.roles?.map((x) => x.name).join(", ")}</p>,
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      render: (_, row) => {
        if (row.isMasterUser) {
          return <p>Tenant Owner</p>;
        } else {
          return <p>{row.userType.name}</p>;
        }
      },
    },
  ];

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8 text-left">
          <h2 className="sm:text-xl xxl:text-2xl font-medium flex-1">Users</h2>
          <ActionAccessWrapper permissionKeys={"CreateUser"}>
            <a
              onClick={() => openModal()}
              id="addProductTypeBtn"
              className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add user
            </a>
          </ActionAccessWrapper>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Add User"}
          >
            <AddUser
              form={form}
              id={id}
              onFinish={onFinish}
              role={role}
              uploadedUrl={uploadedUrl}
              setUploadedUrl={setUploadedUrl}
            />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar usersListing-table">
          <UserTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"user"}
            search={searchText}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            showActions={true}
            permissions={{
              edit: "UpdateUserById",
              status: "UpdateUserById",
              delete: "DeleteUserById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default User;
