/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "./menu";
import { useSelector } from "react-redux";
import {
  getUserDetails,
  getUserPermissions,
} from "../../../redux/feature/authSlice";
import { filterSidebarLinks } from "./hepler";

function SideBar() {
  const [openIndex, setOpenIndex] = useState(null);
  const [menuWithPermission, setMenuWithPermission] = useState([]);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const { user } = useSelector(getUserDetails) || {};
  const allowedPermissionFromStore = useSelector(getUserPermissions) || {};

  let submenuTimer = null;

  useEffect(() => {
    const filteredSidebar = Menu.filter((item) =>
      item.permission.includes(user.userType),
    );
    if (allowedPermissionFromStore.masterUser == true) {
      setMenuWithPermission(filteredSidebar);
    } else {
      const filteredSidebarWithPermission = filterSidebarLinks(
        filteredSidebar,
        allowedPermissionFromStore,
      );
      setMenuWithPermission(filteredSidebarWithPermission);
    }
  }, [allowedPermissionFromStore]);

  const toggleDropdown = (index) => {
    if (isSidebarCollapsed) return;
    setOpenIndex(openIndex === index ? null : index);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
    setOpenIndex(null);
    removeClonedSubmenu();
  };

  const handleHoverEnter = (menu, index, event) => {
    if (!isSidebarCollapsed || !menu.children) return;

    clearTimeout(submenuTimer);
    removeClonedSubmenu();

    const parentRect = event.currentTarget.getBoundingClientRect();

    const submenuElement = document.createElement("div");
    submenuElement.id = "cloned-submenu";
    submenuElement.className =
      "absolute bg-veryLightGrey shadow-lg rounded-md py-2 border w-48";
    submenuElement.style.position = "absolute";
    submenuElement.style.top = `${parentRect.top + window.scrollY}px`;
    submenuElement.style.left = `${parentRect.right + 10}px`;
    submenuElement.style.zIndex = 1000;

    menu.children.forEach((child) => {
      const childElement = document.createElement("div");
      childElement.className =
        "block text-sm py-2 px-4 hover:bg-redOrange hover:text-white cursor-pointer";
      childElement.textContent = child.label;

      childElement.addEventListener("click", () => {
        window.history.pushState({}, "", `/${child.to}`);
        const navigationEvent = new PopStateEvent("popstate");
        window.dispatchEvent(navigationEvent);
      });

      submenuElement.appendChild(childElement);
    });

    submenuElement.addEventListener("mouseenter", () => {
      clearTimeout(submenuTimer);
      submenuElement.setAttribute("data-hover", "true");
    });

    submenuElement.addEventListener("mouseleave", () => {
      submenuElement.removeAttribute("data-hover");
      hideSubmenuWithDelay();
    });

    document.body.appendChild(submenuElement);
  };

  const handleHoverLeave = () => {
    hideSubmenuWithDelay();
  };

  const hideSubmenuWithDelay = () => {
    submenuTimer = setTimeout(() => {
      if (!document.querySelector("#cloned-submenu[data-hover]")) {
        removeClonedSubmenu();
      }
    }, 300);
  };

  const removeClonedSubmenu = () => {
    const existingSubmenu = document.querySelector("#cloned-submenu");
    if (existingSubmenu) {
      document.body.removeChild(existingSubmenu);
    }
  };

  return (
    <aside
      className={`sidebarParentDiv bg-white flex flex-col sticky top-0 left-0 rounded-[1.8rem] flex-none ${
        isSidebarCollapsed ? "w-16" : "w-52"
      } h-full transition-all duration-300`}
    >
      <div className="sidebarHeadWrap flex items-center justify-between gap-2 py-8 px-4 cursor-pointer">
        {!isSidebarCollapsed && (
          <a className="block sidebarLogo">
            <img
              src="/img/logo.svg"
              alt="Logo"
              className="w-24 mx-auto transition-all duration-300"
            />
          </a>
        )}
        <div
          className={`sidebarToggleButton bg-ufoGreen w-8 aspect-square rounded-full flex items-center justify-center ${!isSidebarCollapsed ? "rotate-180" : "rotate-0"}`}
          onClick={toggleSidebar}
        >
          <img
            src="/img/sidebarToggle.png"
            alt="Toggle"
            className="w-4 mx-auto h-auto brightness-[100]"
          />
        </div>
      </div>
      <ul className="scrollbar overflow-y-auto sidebarItemList relative">
        {menuWithPermission.map((menu, index) => (
          <li
            key={index}
            className={`group mb-3 last:mb-0 ${
              menu?.children?.length ? "menu-item-has-children" : ""
            } ${openIndex === index ? "active" : ""}`}
            onMouseEnter={(e) => handleHoverEnter(menu, index, e)}
            onMouseLeave={handleHoverLeave}
          >
            <Link
              to={menu?.children?.length ? "#" : menu.to}
              onClick={() => toggleDropdown(index)}
            >
              <div className="relative py-1 px-3 flex items-center justify-start cursor-pointer transition ease-in-out group-hover:bg-redOrange group-[.active]:bg-redOrange sidebarItemListing">
                {menu.icon}
                {!isSidebarCollapsed && (
                  <span className="sidebarItemListingContent text-sm text-lightGrey group-hover:text-white group-[.active]:text-white ml-2">
                    {menu.label}
                  </span>
                )}
                {menu?.children?.length && (
                  <span
                    className={`toggleArrow absolute top-1/2 right-4 transition-transform ${
                      isSidebarCollapsed
                        ? "hidden"
                        : openIndex === index
                          ? "rotate-180"
                          : ""
                    }`}
                  >
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-black group-[.active]:stroke-white"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        // stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </Link>
            {!isSidebarCollapsed && openIndex === index && menu.children && (
              <ul className="sidebarItemListingSubmenu bg-veryLightGrey py-2 transition-all ease-in-out duration-300">
                {menu.children.map((child, childIndex) => (
                  <li key={childIndex}>
                    <Link
                      to={child.to}
                      className="text-left block text-sm font-medium text-black py-2 pr-4 pl-10"
                    >
                      {child.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;
