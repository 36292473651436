import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, Checkbox, Collapse } from "antd";
import { useState } from "react";
const { Panel } = Collapse;

function AddPermisions({ form, onFinish, permissions }) {
  const [tempCheckbox, setTempCheckbox] = useState([]);

  useEffect(() => {
    let checkedPermissions = [];
    Object.keys(permissions).forEach((key) => {
      checkedPermissions = checkedPermissions.concat(
        permissions[key].filter((permission) => permission.checked),
      );
    });
    const checkedPermissionIds = checkedPermissions.map(
      (permission) => permission.permissionId,
    );
    form.setFieldsValue({
      permissions: checkedPermissionIds,
    });
    setTempCheckbox(checkedPermissionIds);
  }, [permissions, form]);

  useEffect(() => {
    form.setFieldsValue({
      permissions: tempCheckbox,
    });
  }, [tempCheckbox]);

  const onClickCheckbox = (e) => {
    const temp = [...tempCheckbox];
    const value = e.target.value;

    if (temp.includes(value)) {
      const updatedTemp = temp.filter((id) => id !== value);
      form.setFieldsValue({
        permissions: updatedTemp,
      });
      setTempCheckbox(updatedTemp);
    } else {
      temp.push(value);
      form.setFieldsValue({
        permissions: temp,
      });
      setTempCheckbox(temp);
    }
  };

  const onClickCheckAll = (e) => {
    const groupKey = e.target.value; // The group key
    const tempPermissionArray = permissions[groupKey]; // Permissions for the clicked group

    // Extract all permission IDs for the group
    const groupPermissionIds = tempPermissionArray.map(
      (permission) => permission.permissionId,
    );

    // Check if any of the groupPermissionIds already exist in tempCheckbox
    const hasDuplicate = groupPermissionIds.some((id) =>
      tempCheckbox.includes(id),
    );

    if (hasDuplicate) {
      // Remove all permission IDs of this group from tempCheckbox
      const updatedTempCheckbox = tempCheckbox.filter(
        (id) => !groupPermissionIds.includes(id),
      );
      form.setFieldsValue({
        permissions: updatedTempCheckbox,
      });
      setTempCheckbox(updatedTempCheckbox);
    } else {
      // Add all permission IDs of this group to tempCheckbox
      const updatedTempCheckbox = [...tempCheckbox, ...groupPermissionIds];
      form.setFieldsValue({
        permissions: updatedTempCheckbox,
      });
      setTempCheckbox(updatedTempCheckbox);
    }
  };

  const accordianHeader = (key) => {
    return (
      <Row>
        <Col span={12}>{key}</Col>
      </Row>
    );
  };

  const accordianCheckALlButton = (key) => {
    const groupPermissionIds = permissions[key].map(
      (permission) => permission.permissionId,
    );
    const isAllChecked = groupPermissionIds.every((id) =>
      tempCheckbox.includes(id),
    );
    return (
      <Row>
        <Col span={12} className="mb-4">
          <Checkbox
            onClick={onClickCheckAll}
            checked={isAllChecked}
            value={key}
            className="pr-3 font-20"
          >
            <p className="text-sm font-bold">Check all</p>
          </Checkbox>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="permissions">
          <Collapse
            size="large"
            expandIconPosition="end"
            className="permission-collapse"
            defaultActiveKey="Role"
          >
            {Object.keys(permissions).map((permKey) => (
              <Panel header={accordianHeader(permKey)} key={permKey}>
                {accordianCheckALlButton(permKey)}
                <div className="permission-checkbox-main">
                  {Object.values(permissions[permKey]).map((permission) => (
                    <label className="permission-checkbox">
                      <Checkbox
                        onClick={onClickCheckbox}
                        value={permission.permissionId}
                        checked={tempCheckbox.includes(permission.permissionId)}
                      />
                      <span>{permission.value}</span>
                    </label>
                  ))}
                </div>
              </Panel>
            ))}
          </Collapse>
        </Form.Item>
        {/* <Row gutter={16}>
          <Col span={24}>
            <span className="text-lightGrey font-medium mb-5">Permissions</span>
            <Form.Item name="permissions">
              <Checkbox.Group>
                {Object.keys(permissions).map((permKey) =>
                  Object.values(permissions[permKey]).map((permission) => (
                    <Row key={permission.permissionId} gutter={16}>
                      <Col span={24}>
                        <label>
                          <Checkbox value={permission.permissionId} />
                          <span>{permission.value}</span>
                        </label>
                      </Col>
                    </Row>
                  )),
                )}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row> */}
        {/* Submit Button */}
        <div className="flex items-center justify-end gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddPermisions;
