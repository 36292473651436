import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import CustomSelect from "../../shared/customSelect/indes";
import { getAllProduct } from "../../../services/product";
import { ServerError } from "../../shared/notification";
import { dropDownPayload } from "../../../utils/helper";
import CompositePricing from "./compositeProductPricing";

function ComponentProduct({ form }) {
  const { Option } = Select;
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const getProducts = async () => {
    try {
      const {
        data: { data },
      } = await getAllProduct();
      const pros = [];
      for (const element of data.data) {
        for (const vari of element.variants) {
          pros.push({
            _id: `${element._id}_${vari._id}`,
            productId: `${element._id}`,
            name: `${element.name} / ${vari.combination.join(" / ")}`,
            combination: vari.combination,
            variants: [vari],
          });
        }
      }
      setProducts(pros);
    } catch {
      ServerError();
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  const onChangeCompositeProduct = (items) => {
    const itemValues = items.map((item) => item.value);
    const tempProduct = products.filter((product) =>
      itemValues.includes(product._id),
    );
    setSelectedProduct(tempProduct);
    let tempvariant = [];
    tempProduct.forEach((data) => {
      const variantsWithProductId = data.variants.map((variant) => ({
        ...variant,
        combination: data.tempProduct,
        productId: data.productId,
      }));
      tempvariant.push(...variantsWithProductId);
    });
    form.setFieldsValue({ compositeProducts: tempvariant });
  };
  return (
    <div>
      <br />
      <Form.Item
        name="compositeProductsIds"
        rules={[
          {
            required: true,
            message: "Please input the composite product name!",
          },
        ]}
      >
        <Select
          // style={{ width: 200 }}
          placeholder="Select an option"
          onChange={onChangeCompositeProduct}
          labelInValue
          getPopupContainer={(trigger) => trigger.parentNode}
          mode="tags"
        >
          {dropDownPayload(products).map((option) => (
            <Option key={option._id} value={option._id}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <CompositePricing form={form} />
    </div>
  );
}

export default ComponentProduct;
