import React, { useEffect, useState } from "react";
import { formatNames } from "../../utils/helper";
import { Form, Input, Select, Button, Tooltip, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

function EditProduct({
  isModalOpen,
  setIsModalOpen,
  tax,
  discountType,
  selectedProductState,
  setSelectedProductState,
  tempProductIndexValue,
}) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [heading, setHeading] = useState("");
  const [product, setProduct] = useState([]);
  useEffect(() => {
    const temp = selectedProductState;
    const productInfo = temp[tempProductIndexValue.index];

    const variantRecord =
      temp[tempProductIndexValue.index]?.variants[
        tempProductIndexValue.varientIndex
      ];
    setProduct({ ...productInfo, variants: variantRecord });
  }, [isModalOpen]);

  // getAllTaxApi
  const onFinishEditProduct = (values) => {
    const temp = selectedProductState;

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].quantity = Number(values.quantity);

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].discount = Number(values.discount);

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].discountTypeId = values.discountTypeId;

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].taxId = values.taxId;

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].retailPriceInclTax = values.retailPriceInclTax;

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].retailPrice = values.retailPrice;

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].price = values.retailPrice;

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].discountType = discountType.find(
      (data) => data._id == values.discountTypeId,
    ).key;

    temp[tempProductIndexValue.index].variants[
      tempProductIndexValue.varientIndex
    ].tax = tax.find((data) => data._id == values.taxId).rate;
    setSelectedProductState(temp);
    form.resetFields();
    setIsModalOpen(false);
  };

  useEffect(() => {
    const temp = selectedProductState;
    const product =
      temp[tempProductIndexValue?.index]?.variants[
        tempProductIndexValue?.varientIndex
      ];
    form.setFieldsValue({
      quantity: Number(product?.quantity),
      costPrice: Number(product?.costPrice),
      discount: 0,
      discountTypeId: product?.discountTypeId,
      retailPriceInclTax: Number(product?.retailPriceInclTax),
      retailPrice: Number(product?.retailPrice),
      price: Number(product?.retailPrice),
      tax: product?.tax,
      taxId: product?.taxId,
    });
    setHeading(
      `${temp[tempProductIndexValue?.index]?.name} ${product?.combination?.length > 0 ? " / " : ""} ${formatNames(product?.combination)}`,
    );
  }, [isModalOpen]);
  const onClickCloseModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  return (
    <>
      <Form onFinish={onFinishEditProduct} form={form}>
        <div className="flex items-center justify-between border-b pb-5 mb-5 pt-5">
          <div className="flex items-center">
            <svg
              onClick={onClickCloseModal}
              className="productCartItemPopupClose mr-10 cursor-pointer"
              width={8}
              height={14}
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 7.99646L8 14.9929"
                stroke="#FE5E5E"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3 className="font-medium text-2xl">{heading}</h3>
          </div>
          <Row>
            <Tooltip
              title={
                <>
                  <Row>Product Name: {product?.name}</Row>
                  <Row>SKU: {product?.skuNo}</Row>
                  <Row>Product Sku: {product?.sku?.name}</Row>
                  <Row>Cost Price: {product?.variants?.costPrice}</Row>
                </>
              }
            >
              <span>
                <InfoCircleOutlined className="text-xl prompt-color" />
              </span>
            </Tooltip>
          </Row>
        </div>
        <div className="flex items-center justify-start gap-4">
          <div className="flex-1">
            <label className="block">
              <span className="block text-sm font-medium mb-2 text-left">
                Quantity
              </span>
              <Form.Item
                name={"quantity"}
                rules={[{ required: true, message: "Please enter quantity" }]}
                className="flex-1"
              >
                <input
                  type="number"
                  min="1"
                  placeholder={1}
                  className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full outline-0"
                />
              </Form.Item>
            </label>
          </div>
          <div className="flex-1">
            <label className="block">
              <span className="block text-sm font-medium mb-2 text-left">
                Retail Price Ex.Tax
              </span>
              <Form.Item
                name={"retailPrice"}
                rules={[{ required: true, message: "Please enter discount" }]}
                className="flex-1"
              >
                <Input
                  style={{ width: "250px" }}
                  defaultValue={"0"}
                  className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full outline-0"
                />
              </Form.Item>
            </label>
          </div>
        </div>
        {selectedProductState[tempProductIndexValue?.index]?.askSerialNo ==
        true ? (
          <>
            <div className="flex items-center justify-start gap-4">
              <div className="flex-1">
                <label className="block">
                  <span className="block text-sm font-medium mb-2 text-left">
                    Serial No
                  </span>
                  <Form.Item
                    name={"serialNo"}
                    rules={[{ required: true, message: "Please enter serial" }]}
                    className="flex-1"
                  >
                    <input
                      placeholder={"Serial Number"}
                      className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full outline-0"
                    />
                  </Form.Item>
                </label>
              </div>
            </div>
          </>
        ) : null}

        <div
          style={{ display: "none" }}
          className="flex items-center justify-start gap-4"
        >
          <div className="flex-1">
            <label className="block">
              <span className="block text-sm font-medium mb-2 text-left">
                Disc Type
              </span>
              <Form.Item name={"discountTypeId"}>
                <Select
                  className="h-auto"
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {discountType.map((discount) => {
                    return (
                      <Option value={discount._id}>{discount.value}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </label>
          </div>
          <div className="flex-1">
            <label className="block">
              <span className="block text-sm font-medium mb-2 text-left">
                Disc Value
              </span>
              <Form.Item
                name={"discount"}
                rules={[{ required: true, message: "Please enter discount" }]}
                className="flex-1"
              >
                <Input
                  style={{ width: "250px" }}
                  defaultValue={"0"}
                  className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full outline-0"
                />
              </Form.Item>
            </label>
          </div>
        </div>
        <div className="flex items-center justify-start">
          <svg
            className="mr-2"
            width={22}
            height={25}
            viewBox="0 0 22 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5999 4.19998H7.3999V4.99998H8.5999V9.39998H9.3999V4.99998H10.5999V4.19998ZM11.6839 7.73998H12.7159L12.9999 9.39998H13.7999L12.9999 4.53598C12.9846 4.44133 12.9357 4.35534 12.8623 4.29367C12.7889 4.232 12.6958 4.19875 12.5999 4.19998H11.7999C11.704 4.19875 11.6109 4.232 11.5375 4.29367C11.4641 4.35534 11.4152 4.44133 11.3999 4.53598L10.5999 9.39998H11.3999L11.6839 7.73998ZM12.1399 4.99998H12.2599L12.5999 6.93998H11.7999L12.1399 4.99998ZM15.0399 9.39998L15.7999 7.75598L16.5599 9.39998H17.4399L16.2399 6.79998L17.4399 4.19998H16.5599L15.7999 5.84398L15.0399 4.19998H14.1599L15.3599 6.79998L14.1599 9.39998H15.0399Z"
              fill="#00E37D"
              stroke="#00E37D"
              strokeWidth="0.2"
            />
            <path
              d="M19 1H6.2C5.66957 1 5.16086 1.21071 4.78579 1.58579C4.41071 1.96086 4.2 2.46957 4.2 3V11H2.2C1.88174 11 1.57652 11.1264 1.35147 11.3515C1.12643 11.5765 1 11.8817 1 12.2V20.6C1 20.9183 1.12643 21.2235 1.35147 21.4485C1.57652 21.6736 1.88174 21.8 2.2 21.8H4.2V22.2C4.2 22.7304 4.41071 23.2391 4.78579 23.6142C5.16086 23.9893 5.66957 24.2 6.2 24.2H15.8C15.8526 24.2003 15.9048 24.1902 15.9536 24.1703C16.0023 24.1504 16.0466 24.1211 16.084 24.084L20.884 19.284C20.9211 19.2466 20.9504 19.2023 20.9703 19.1536C20.9902 19.1048 21.0003 19.0526 21 19V3C21 2.46957 20.7893 1.96086 20.4142 1.58579C20.0391 1.21071 19.5304 1 19 1ZM1.8 20.6V12.2C1.8 12.0939 1.84214 11.9922 1.91716 11.9172C1.99217 11.8421 2.09391 11.8 2.2 11.8H9C9.10608 11.8 9.20783 11.8421 9.28284 11.9172C9.35785 11.9922 9.4 12.0939 9.4 12.2V20.6C9.4 20.7061 9.35785 20.8078 9.28284 20.8828C9.20783 20.9579 9.10608 21 9 21H2.2C2.09391 21 1.99217 20.9579 1.91716 20.8828C1.84214 20.8078 1.8 20.7061 1.8 20.6ZM15.8 18.6C15.6939 18.6 15.5922 18.6421 15.5172 18.7172C15.4421 18.7922 15.4 18.8939 15.4 19V23.4H6.2C5.88174 23.4 5.57651 23.2736 5.35147 23.0485C5.12643 22.8235 5 22.5183 5 22.2V21.8H9C9.31826 21.8 9.62348 21.6736 9.84853 21.4485C10.0736 21.2235 10.2 20.9183 10.2 20.6V12.2C10.2 11.8817 10.0736 11.5765 9.84853 11.3515C9.62348 11.1264 9.31826 11 9 11H5V3C5 2.68174 5.12643 2.37652 5.35147 2.15147C5.57651 1.92643 5.88174 1.8 6.2 1.8H19C19.3183 1.8 19.6235 1.92643 19.8485 2.15147C20.0736 2.37652 20.2 2.68174 20.2 3V18.6H15.8ZM16.2 22.836V19.4H19.636L16.2 22.836Z"
              fill="#00E37D"
              stroke="#00E37D"
              strokeWidth="0.2"
            />
            <path
              d="M8.2001 12.6H3.0001C2.89401 12.6 2.79227 12.6421 2.71725 12.7171C2.64224 12.7922 2.6001 12.8939 2.6001 13V15C2.6001 15.1061 2.64224 15.2078 2.71725 15.2828C2.79227 15.3578 2.89401 15.4 3.0001 15.4H8.2001C8.30618 15.4 8.40792 15.3578 8.48294 15.2828C8.55795 15.2078 8.6001 15.1061 8.6001 15V13C8.6001 12.8939 8.55795 12.7922 8.48294 12.7171C8.40792 12.6421 8.30618 12.6 8.2001 12.6ZM7.8001 14.6H3.4001V13.4H7.8001V14.6ZM7.0001 16.2H8.2001V17H7.0001V16.2ZM3.0001 16.2H4.2001V17H3.0001V16.2ZM5.0001 16.2H6.2001V17H5.0001V16.2ZM7.0001 17.8H8.2001V18.6H7.0001V17.8ZM3.0001 17.8H4.2001V18.6H3.0001V17.8ZM5.0001 17.8H6.2001V18.6H5.0001V17.8ZM7.0001 19.4H8.2001V20.2H7.0001V19.4ZM3.0001 19.4H4.2001V20.2H3.0001V19.4ZM5.0001 19.4H6.2001V20.2H5.0001V19.4ZM11.4001 11.8H17.8001V12.6H11.4001V11.8ZM11.4001 13.8H16.2001V14.6H11.4001V13.8ZM11.4001 15.8H17.8001V16.6H11.4001V15.8ZM11.4001 17.8H14.6001V18.6H11.4001V17.8Z"
              fill="#00E37D"
              stroke="#00E37D"
              strokeWidth="0.2"
            />
          </svg>
          <span className="text-sm text-ufoGreen">Tax Rate</span>
        </div>
        <label htmlFor="" className="block relative">
          <Form.Item
            name={"taxId"}
            className="flex-1"
            rules={[{ required: true, message: "Please enter tax rate" }]}
          >
            <Select
              placeholder="Tax"
              getPopupContainer={(trigger) => trigger.parentNode}
              className="h-auto mt-2"
            >
              {tax.map((data, key) => {
                return (
                  <Option key={key} value={data._id}>
                    {data.name} {data.rate}%
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {/* <svg
            className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
            width={13}
            height={8}
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.53765 7.2674C6.54517 7.25838 6.54817 7.24637 6.55568 7.23735L11.8008 1.44236C12.0938 1.11633 12.0938 0.587462 11.8008 0.261428C11.7978 0.258423 11.7948 0.256921 11.7918 0.255418C11.7266 0.176472 11.6449 0.112712 11.5525 0.0685952C11.4602 0.0244781 11.3593 0.00106779 11.2569 0H0.765203C0.660951 0.00168458 0.558339 0.0262401 0.464618 0.0719323C0.370897 0.117625 0.288353 0.183339 0.222815 0.264433L0.21981 0.261428C0.0780233 0.425475 0 0.635065 0 0.851895C0 1.06872 0.0780233 1.27831 0.21981 1.44236L5.47692 7.2674C5.54237 7.34412 5.62368 7.40572 5.71524 7.44797C5.80681 7.49021 5.90645 7.51209 6.00729 7.51209C6.10813 7.51209 6.20777 7.49021 6.29933 7.44797C6.3909 7.40572 6.47221 7.34412 6.53765 7.2674Z"
              fill="#BABABA"
            />
          </svg> */}
        </label>
        <div className="flex items-center justify-start gap-4">
          {/* <div className="flex-1">
            <label className="block">
              <span className="block text-sm font-medium mb-2">
                Price Including Tax
              </span>
              <Form.Item
                name={"retailPriceInclTax"}
                rules={[{ required: true, message: "Please enter price" }]}
                className="flex-1"
              >
                <input
                disabled
                  type="number"
                  placeholder={100}
                  className="block text-sm w-full border rounded-full py-3 px-3 leading-none text-center outline-0"
                />
              </Form.Item>
            </label>
          </div> */}
        </div>
        <div className="flex items-center justify-center gap-3 mt-10">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Confirm
          </Button>
        </div>
      </Form>
    </>
  );
}

export default EditProduct;
