import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Space,
  Table,
  message,
  Tooltip,
  Row,
  Col,
  Typography,
} from "antd";
import {
  CopyOutlined,
  DownloadOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { getAll } from "../../../services/globalService";
import CustomPopConfirm from "../../../component/shared/popConfirm/popConfirm";
import { useDebounce } from "../../../hooks/useDebounce";
import { getAPIUrl } from "../../../utils/helper";
import { EditOutlined, CloseOutlined, UnlockOutlined } from "@ant-design/icons";
import CustomSwitch from "../../../component/shared/customSwitch";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";
const SaleTable = ({
  columns,
  handleEdit,
  refreshTable,
  endPoint,
  showActions,
  handleStatus,
  handleView,
  handleDuplicate,
  dataSource,
  search,
  scroll,
  handleDelete,
  handlePublish,
  filters,
  handleDownload,
  actionsWidth = 200,
  showSelectionRow,
  handleSelectedRow,
  searchRangePicker,
  handlePdfDownload,
  hidePagination,
  mapIdToKey,
  handleRefund,
  permissions,
}) => {
  const { Text } = Typography;
  const onCancel = () => {
    message.error("canceled");
  };
  const actionColumn = {
    title: "Action",
    width: actionsWidth,
    fixed: "right",
    className: "table-action-column",
    key: "action",
    render: (currentRow) => {
      const { isActive } = currentRow;
      return (
        <Space size={1}>
          {handleView && (
            <Button
              className="transparent-button"
              onClick={() => handleView(currentRow)}
            />
          )}
          {handleDownload && (
            <Button
              className="transparent-button"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(currentRow)}
            />
          )}
          {handlePublish && (
            <Button
              onClick={() => handlePublish(currentRow)}
              className="transparent-button"
              icon={<UnlockOutlined />}
            />
          )}
          {handleStatus && (
            <ActionAccessWrapper permissionKeys={permissions?.status}>
              <CustomPopConfirm
                description={"Are you sure?"}
                onConfirm={() => handleStatus(currentRow)}
                onCancel={onCancel}
                okText={"Ok"}
                cancelText={"Cancel"}
                placement="leftTop"
                icon={null}
              >
                <Button className="transparent-button transparent-switch-button">
                  <CustomSwitch isChecked={isActive} />
                </Button>
              </CustomPopConfirm>
            </ActionAccessWrapper>
          )}
          {handleEdit && (
            <ActionAccessWrapper permissionKeys={permissions?.edit}>
              {currentRow?.paymentStatus == "PARKED" ? (
                <Button
                  className="transparent-button"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(currentRow)}
                />
              ) : null}
            </ActionAccessWrapper>
          )}
          {handleRefund && (
            <Button
              className="transparent-button"
              icon={
                <Tooltip title="Refund">
                  <RetweetOutlined />
                </Tooltip>
              }
              onClick={() => handleRefund(currentRow)}
            />
          )}
          {handlePdfDownload && (
            <Button
              className="transparent-button"
              onClick={() => handlePdfDownload(currentRow)}
            />
          )}
          {handleDelete && (
            <ActionAccessWrapper permissionKeys={permissions?.delete}>
              <CustomPopConfirm
                description={"Do you want to continue?"}
                onConfirm={() => handleDelete(currentRow)}
                onCancel={onCancel}
                okText={"Ok"}
                cancelText={"Cancel"}
                placement="leftTop"
                icon={null}
              >
                <Button
                  className="transparent-button transparent-switch-button"
                  icon={<CloseOutlined />}
                ></Button>
              </CustomPopConfirm>
            </ActionAccessWrapper>
          )}
          {handleDuplicate && (
            <CustomPopConfirm
              description={"Do you want to continue?"}
              onConfirm={() => handleDuplicate(currentRow)}
              onCancel={onCancel}
              okText={"Ok"}
              cancelText={"Cancel"}
              placement="leftTop"
              icon={null}
            >
              <Button className="transparent-button" icon={<CopyOutlined />} />
            </CustomPopConfirm>
          )}
        </Space>
      );
    },
  };
  const columnsIntoArray = [...(columns ?? [])];
  // #states
  const [columnsWithAction, setColumnsWithAction] = useState(columnsIntoArray);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [total, setTotal] = useState(10);
  const [caltulatedValue, setCaltulatedValue] = useState("");

  useEffect(() => {
    if (dataSource?.length) {
      setTotal(dataSource?.length);
    }
  }, [dataSource]);
  const debouncedValue = useDebounce(search || searchRangePicker || "");
  const fetchData = useCallback(
    async (paginationConfig) => {
      setLoading(true);
      try {
        const queryParams = {
          take: paginationConfig.pageSize,
          page: debouncedValue ? 1 : paginationConfig.current,
          name: debouncedValue,
          ...(filters && { ...filters }),
        };
        const { data: response } = await getAll(
          getAPIUrl(endPoint, queryParams),
        );
        const { meta, data: record } = response;
        setTotal(meta?.totalRecords);
        let newRecords = record;
        if (showSelectionRow) {
          newRecords = record?.map((item, index) => ({ ...item, key: index }));
        }
        if (mapIdToKey) {
          mapIdToKeys(newRecords);
        }
        setData(newRecords.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [debouncedValue, filters],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapIdToKeys = (records) => {
    records.forEach((record, index) => {
      record.key = record.id || `${index} - ${record.key}`;
    });
  };

  const updateDataSource = useCallback(() => {
    if (dataSource) {
      setLoading(true);
      const tempData = [...dataSource];
      const startPoint =
        Number(pagination.pageSize) * (Number(pagination.current) - 1);

      const limitedData = tempData.splice(
        startPoint,
        Number(pagination.pageSize),
      );
      setData(limitedData);
      setLoading(false);
    }
  }, [dataSource, pagination]);

  // #Effects
  useEffect(() => {
    if (showActions) {
      setColumnsWithAction([...columnsIntoArray, actionColumn]);
    }
  }, [showActions]);

  useEffect(() => {
    if (endPoint) fetchData(pagination);
    else if (dataSource) updateDataSource();
  }, [
    pagination,
    fetchData,
    updateDataSource,
    endPoint,
    refreshTable,
    dataSource,
    debouncedValue,
  ]);

  useMemo(() => {
    setPagination({ current: 1, pageSize: 10 });
  }, [filters]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, currentRow) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (currentRow) {
      handleSelectedRow?.(currentRow);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    calculateValues(data);
  }, [
    pagination,
    fetchData,
    updateDataSource,
    endPoint,
    refreshTable,
    dataSource,
    debouncedValue,
    ,
    data,
  ]);
  const calculateValues = (record) => {
    const total = record.reduce(
      (acc, order) => {
        acc.subTotal += order.subTotal || 0;
        acc.discount += order.discountAmount || 0;
        acc.tax += order.tax || 0;
        acc.remainingBalance += order.remainingBalance || 0;
        return acc;
      },
      { subTotal: 0, discount: 0, tax: 0, remainingBalance: 0 },
    );
    setCaltulatedValue(total);
  };

  return (
    <div className={"custom-table-main"}>
      <div className="gap-4 grid grid-cols-12 mt-4 mb-10 flex-1 items-start text-left">
        <div className="col-span-4 rounded-2xl p-4 bg-white">
          <h3 className="font-medium text-lg text-ufoGreen mb-1 border-b pb-1 border-ufoGreen">
            Total Sales
          </h3>
          <div className="text-ufoGreen text-2xl font-bold">
            {Number(caltulatedValue?.subTotal).toFixed(2)}
          </div>
        </div>

        <div className="col-span-4 rounded-2xl p-4 bg-white">
          <h3 className="font-medium text-lg text-redOrange mb-1 border-b pb-1 border-redOrange">
            Total Discount Given
          </h3>
          <div className="text-redOrange text-2xl font-bold">
            {Number(caltulatedValue?.discount).toFixed(2)}
          </div>
        </div>

        <div className="col-span-4 rounded-2xl p-4 bg-white">
          <h3 className="font-medium text-lg text-ufoGreen mb-1 border-b pb-1 border-ufoGreen">
            Total Tax Collected
          </h3>
          <div className="text-ufoGreen text-2xl font-bold">
            {Number(caltulatedValue?.tax).toFixed(2)}
          </div>
        </div>
      </div>
      {/* <Row className="ml-2 mb-1">
        <Col span={6} className="flex justify-start">
          <Text type="danger">
            Sale Total: {Number(caltulatedValue?.subTotal).toFixed(2)}
          </Text>
        </Col>
        <Col span={6} className="flex justify-start">
          <Text type="danger">
            Discount: {Number(caltulatedValue?.discount).toFixed(2)}
          </Text>
        </Col>
        <Col span={6} className="flex justify-start">
          <Text type="danger">
            Tax: {Number(caltulatedValue?.tax).toFixed(2)}
          </Text>
        </Col>
        <Col span={6} className="flex justify-start">
          <Text type="danger">
            Remaining Balance:{" "}
            {Number(caltulatedValue?.remainingBalance).toFixed(2)}
          </Text>
        </Col>
      </Row> */}
      <Table
        columns={columnsWithAction}
        dataSource={data}
        pagination={!hidePagination ? { ...pagination, total } : false}
        onChange={setPagination}
        loading={loading}
        scroll={scroll}
        rowSelection={showSelectionRow ? rowSelection : undefined}
        className="products-table"
      />
    </div>
  );
};

export default SaleTable;
