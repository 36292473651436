import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the required Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ title, labels, values }) => {
  function getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = 0.7; // Adjust alpha (transparency) as needed
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
  const backgroundColors = labels.map(() => getRandomColor());
  const data = {
    labels,
    datasets: [
      {
        label: title, // Dataset label
        data: values, // Data values for each segment
        backgroundColor: backgroundColors,
        borderColor: backgroundColors.map((color) =>
          color.replace(/0\.7\)$/, "1)"),
        ),
        borderWidth: 1, // Border width
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top", // Position of the legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
