import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";

const PhoneNumberInput = ({
  value,
  onChange,
  placeholder = "Enter phone number",
  ...rest
}) => {
  return (
    <PhoneInput
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      international
      withCountryCallingCode
      className="custom-phone-input rounded-full px-6 py-3"
      {...rest} // Pass other props for further customization
    />
  );
};
export default PhoneNumberInput;
