import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Space, Table, message } from "antd";
import {
  CopyOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { getAll } from "../../services/globalService";
import CustomPopConfirm from "../../component/shared/popConfirm/popConfirm";
import { useDebounce } from "../../hooks/useDebounce";
import CustomSwitch from "../../component/shared/customSwitch";
import { EditOutlined, CloseOutlined, UnlockOutlined } from "@ant-design/icons";
import { getAPIUrl } from "../../utils/helper";
import ActionAccessWrapper from "../shared/actionAccessWrapper";

const UserTable = ({
  columns,
  handleEdit,
  refreshTable,
  endPoint,
  showActions,
  handleStatus,
  handleView,
  handleDuplicate,
  dataSource,
  search,
  scroll,
  handleDelete,
  handlePublish,
  filters,
  handleDownload,
  actionsWidth = 200,
  showSelectionRow,
  handleSelectedRow,
  searchRangePicker,
  handlePdfDownload,
  hidePagination,
  mapIdToKey,
  permissions,
}) => {
  const onCancel = () => {
    message.error("canceled");
  };
  const actionColumn = {
    title: "Action",
    width: actionsWidth,
    fixed: "right",
    className: "table-action-column",
    key: "action",
    render: (currentRow) => {
      const { isActive } = currentRow;
      return (
        <Space size={1}>
          {handleView && (
            <ActionAccessWrapper permissionKeys={permissions}>
              <Button
                className="transparent-button"
                onClick={() => handleView(currentRow)}
              />
            </ActionAccessWrapper>
          )}
          {handleDownload && (
            <Button
              className="transparent-button"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(currentRow)}
            />
          )}
          {handlePublish && (
            <Button
              onClick={() => handlePublish(currentRow)}
              className="transparent-button"
              icon={<UnlockOutlined />}
            />
          )}
          {handleStatus && (
            <ActionAccessWrapper permissionKeys={permissions?.status}>
              <CustomPopConfirm
                description={"Are you sure?"}
                onConfirm={() => handleStatus(currentRow)}
                onCancel={onCancel}
                okText={"Ok"}
                cancelText={"Cancel"}
                placement="leftTop"
                icon={null}
              >
                <Button className="transparent-button transparent-switch-button">
                  <CustomSwitch
                    isChecked={isActive}
                    isDisabled={currentRow.isMasterUser || false}
                  />
                </Button>
              </CustomPopConfirm>
            </ActionAccessWrapper>
          )}
          {handleEdit && (
            <ActionAccessWrapper permissionKeys={permissions?.edit}>
              <Button
                className="transparent-button"
                icon={<EditOutlined />}
                onClick={() => handleEdit(currentRow)}
                disabled={currentRow.isMasterUser || false}
              />
            </ActionAccessWrapper>
          )}
          {handlePdfDownload && (
            <Button
              className="transparent-button"
              onClick={() => handlePdfDownload(currentRow)}
            />
          )}
          {handleDelete && (
            <ActionAccessWrapper permissionKeys={permissions?.delete}>
              <CustomPopConfirm
                description={"Do you want to continue?"}
                onConfirm={() => handleDelete(currentRow)}
                onCancel={onCancel}
                okText={"Ok"}
                cancelText={"Cancel"}
                placement="leftTop"
                icon={null}
              >
                <Button
                  className="transparent-button transparent-switch-button"
                  icon={<CloseOutlined />}
                  disabled={currentRow.isMasterUser || false}
                ></Button>
              </CustomPopConfirm>
            </ActionAccessWrapper>
          )}
          {handleDuplicate && (
            <CustomPopConfirm
              description={"Do you want to continue?"}
              onConfirm={() => handleDuplicate(currentRow)}
              onCancel={onCancel}
              okText={"Ok"}
              cancelText={"Cancel"}
              placement="leftTop"
              icon={null}
            >
              <Button className="transparent-button" icon={<CopyOutlined />} />
            </CustomPopConfirm>
          )}
        </Space>
      );
    },
  };
  const columnsIntoArray = [...(columns ?? [])];
  // #states
  const [columnsWithAction, setColumnsWithAction] = useState(columnsIntoArray);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [total, setTotal] = useState(10);

  useEffect(() => {
    if (dataSource?.length) {
      setTotal(dataSource?.length);
    }
  }, [dataSource]);
  const debouncedValue = useDebounce(search || searchRangePicker || "");
  const fetchData = useCallback(
    async (paginationConfig) => {
      setLoading(true);
      try {
        const queryParams = {
          take: paginationConfig.pageSize,
          page: debouncedValue ? 1 : paginationConfig.current,
          name: debouncedValue,
          ...(filters && { ...filters }),
        };
        const { data: response } = await getAll(
          getAPIUrl(endPoint, queryParams),
        );
        const { meta, data: record } = response;
        setTotal(meta?.totalRecords);
        let newRecords = record;
        if (showSelectionRow) {
          newRecords = record?.map((item, index) => ({ ...item, key: index }));
        }
        if (mapIdToKey) {
          mapIdToKeys(newRecords);
        }
        setData(newRecords.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [debouncedValue, filters],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapIdToKeys = (records) => {
    records.forEach((record, index) => {
      record.key = record.id || `${index} - ${record.key}`;
    });
  };

  const updateDataSource = useCallback(() => {
    if (dataSource) {
      setLoading(true);
      const tempData = [...dataSource];
      const startPoint =
        Number(pagination.pageSize) * (Number(pagination.current) - 1);

      const limitedData = tempData.splice(
        startPoint,
        Number(pagination.pageSize),
      );
      setData(limitedData);
      setLoading(false);
    }
  }, [dataSource, pagination]);

  // #Effects
  useEffect(() => {
    if (showActions) {
      setColumnsWithAction([...columnsIntoArray, actionColumn]);
    }
  }, [showActions]);

  useEffect(() => {
    if (endPoint) fetchData(pagination);
    else if (dataSource) updateDataSource();
  }, [
    pagination,
    fetchData,
    updateDataSource,
    endPoint,
    refreshTable,
    dataSource,
    debouncedValue,
  ]);

  useMemo(() => {
    setPagination({ current: 1, pageSize: 10 });
  }, [filters]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, currentRow) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (currentRow) {
      handleSelectedRow?.(currentRow);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className={"custom-table-main"}>
      <Table
        columns={columnsWithAction}
        dataSource={data}
        pagination={!hidePagination ? { ...pagination, total } : false}
        onChange={setPagination}
        loading={loading}
        scroll={scroll}
        rowSelection={showSelectionRow ? rowSelection : undefined}
        className="products-table"
      />
    </div>
  );
};

export default UserTable;
