import { Form, Input } from "antd";
import React from "react";
import CustomSelect from "../shared/customSelect/indes";
import { dropDownPayload } from "../../utils/helper";

const paymentType = [
  {
    id: 1,
    key: "CASH",
    label: "Cash",
  },
  {
    id: 2,
    key: "CARD",
    label: "Card",
  },
];
function Information({
  outlet,
  customer,
  selectedProduct,
  setSelectedProduct,
}) {
  const handleRadioChange = (key) => {
    setSelectedProduct(key);
  };
  return (
    <>
      <div className="grid grid-cols-4 gap-5">
        <Form.Item
          name="purchaseDate"
          rules={[
            { required: true, message: "Please input the Transfer date!" },
          ]}
        >
          <Input
            type="date"
            placeholder="Transfer Date"
            className="block leading-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
          />
        </Form.Item>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <Form.Item
          name="outletId"
          rules={[{ required: true, message: "Please input the outlet!" }]}
        >
          <CustomSelect
            options={dropDownPayload(outlet)}
            placeholder={"Select Outlet"}
          />
        </Form.Item>

        <Form.Item
          name="customerId"
          rules={[{ required: true, message: "Please input the supplier!" }]}
        >
          <CustomSelect
            options={dropDownPayload(customer)}
            placeholder={"Select Supplier"}
          />
        </Form.Item>
      </div>

      <div className="">
        <Form.Item
          name="notes"
          // label={<span className="text-sm text-lightGrey">Frieght</span>}
          rules={[
            {
              required: true,
              message: "Please enter a notes",
            },
          ]}
        >
          <Input
            className="border border-lightGrey rounded-2xl resize-none sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
            placeholder="Enter your notes here"
          />
        </Form.Item>
      </div>

      <span className="text-sm text-lightGrey">Payment Type</span>
      <div className="grid grid-cols-8 mt-3 mb-5">
        {paymentType.map((data, index) => (
          <div className="inline-flex items-center" key={index}>
            <label
              className="relative flex items-center cursor-pointer"
              htmlFor={`product-radio-${data.key}`}
            >
              <input
                name={data.key}
                type="radio"
                className="peer w-[1.1rem] aspect-square cursor-pointer appearance-none rounded-full border border-lightGrey checked:border-redOrange transition-all"
                id={`product-radio-${data.key}`}
                checked={selectedProduct === data.key}
                onChange={() => handleRadioChange(data.key)}
                defaultChecked={paymentType[0].key}
              />
              <span className="absolute bg-redOrange w-[0.6rem] aspect-square rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-[0.3rem] left-[0.25rem]" />
              <span
                className="ml-2 text-lightGrey peer-checked:text-black cursor-pointer text-sm"
                htmlFor={`product-radio-${data.key}`}
              >
                {data.label}
              </span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
}

export default Information;
