import React, { useEffect, useState } from "react";
import CustomModal from "../../../component/shared/modal";
import SearchInput from "../../../component/shared/searchInput";
import AddProductType from "../../../component/products/addProductType";
import CustomTable from "../../../component/shared/customTable";
import {
  changeProductTypeStatus,
  createProductType,
  deleteProductTypeApi,
  getAllProductTypeApi,
  updateProductType,
} from "../../../services/product/productType";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { Form, Image } from "antd";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";

function ProductType() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const getAllProductType = async () => {
    try {
      const { data: response } = await getAllProductTypeApi();
      const { data: record } = response;
      const tempData = record.data.map((record) => ({
        label: record.name,
        value: record._id,
      }));
      setData(tempData);
    } catch {
      return ServerError();
    }
  };

  useEffect(() => {
    getAllProductType();
  }, []);

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeProductTypeStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteProductTypeApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateProductType(payload, id);
      } else {
        response = await createProductType(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Product Type successfully saved");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  const onClickEdit = async (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setUploadedUrl(row.image);
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      className: "!p-1",
      render: (_, row) => {
        return (
          <Image
            className="!w-12 aspect-square object-cover rounded-full border border-veryLightGrey p-0.5"
            src={
              row?.image
                ? row?.image || "/img/no-image.png"
                : "/img/no-image.png"
            }
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Parent",
      dataIndex: "parentType",
      key: "parentType",
      render: (_, row) => {
        return row?.parent?.name ? row?.parent?.name : "-";
      },
    },
    {
      title: "Products",
      dataIndex: "count",
      key: "count",
      align: "center",
      render: (_, row) => {
        return row?.count || "0";
      },
    },
  ];

  const onClickAdd = () => {
    openModal();
    form.resetFields();
    setUploadedUrl(null);
  };

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8 text-left">
          <h2 className="sm:text-xl xxl:text-2xl font-medium flex-1">
            Product Category
          </h2>
          <ActionAccessWrapper permissionKeys={"CreateProductType"}>
            <a
              onClick={onClickAdd}
              id="addProductTypeBtn"
              className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add Category
            </a>
          </ActionAccessWrapper>
          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Add Product Category"}
          >
            <AddProductType
              form={form}
              onFinish={onFinish}
              parent={data}
              uploadedUrl={uploadedUrl}
              setUploadedUrl={setUploadedUrl}
            />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex-1 overflow-y-scroll scrollbar productCategorylisting-table">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"product-type/list"}
            search={searchText}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            showActions={true}
            permissions={{
              edit: "UpdateProductTypeById",
              status: "UpdateProductTypeById",
              delete: "DeleteProductTypeById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default ProductType;
